import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HistoricalReports from '../components/HistoricalReports/HistoricalReports';
import MarketReports from '../components/MarketReports/MarketReports';
import { DefaultLayout } from '../layout';
import ReportDownload from '../components/ReportDownload/reportDownload';
import NotFound from '../components/NotFound/NotFound';
import HistoricalReportsDownload from '../components/ReportDownload/HistoricalReportsDownload';
const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route element={<DefaultLayout />} >
          <Route path="/historical-reports" element={<HistoricalReports />} />
          <Route path="/market-reports" element={<MarketReports />} />
          <Route exact path="/market-report/download/:referenceId" element={<ReportDownload/>} />
          <Route exact path="/historical-reports/download/:referenceId" element={<HistoricalReportsDownload />} />
          <Route exact path="/home/:propertyid/:Address/:City/:State/:Zipcode" element={<MarketReports from={"LSS"} />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRouter


