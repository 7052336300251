import axios from "axios";

export const handlingCatchErorr = (props, history) => {
  
    let message = props.response?.data?.Message;
  
    if (props?.response?.status === 401) {
        localStorage.removeItem('accessKey');
        axios.defaults.headers.common["Authorization"] = '';
        const { from } = { from: { pathname: "/page_expired" } };
        history.push(from);
    }
    else if (props.response?.status === 400 && message === "Authentication failed") {
        const { from } = { from: { pathname: "/UnauthorizedAccess" } };
        history.push(from);
    }
    else {
        window.globalThis.REACT_SNACK_OPEN = true;
        window.globalThis.REACT_SNACK_COLOR = "error"; 
        window.globalThis.REACT_SNACK_MESSAGE = message ? message : (props.response?.statusText ? props.response?.statusText : props?.message);
        document.getElementById('enable-error-snack').click();
    }
}