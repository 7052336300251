import axios from 'axios';
import { CONFIG } from '../utils/config';

const baseURL = `/api/` ; 
const baseBIGURL =`${CONFIG.BIG_api_url}/api/`; 


export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  (config) => {
   
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiBIG = axios.create({
    baseURL: baseBIGURL, // Use baseURL instead of baseBIGURL
   
});
  
apiBIG.interceptors.request.use(
    (config) => {
        const apiKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImZyZWUtbWlnQGdtYWlsLmNvbSIsImZpcnN0bmFtZSI6ImZyZWUiLCJ1c2VyaWQiOiIxMTYiLCJ1c2VydHlwZWlkIjoiMiIsInRvdGFsY291bnQiOiIiLCJ1c2VkY291bnQiOiIiLCJzdGF0dXNpZCI6IjEiLCJzdWJzY3JpYmVkU3RhdGVzIjoiIiwic3Vic2NyaWJlZGNvdW50cmllcyI6IiIsInBhY2thZ2VpZHMiOiIyfDYsMnwzLDN8MiIsInBheW1lbnRjdXN0b21lcmlkIjoiIiwicGF5bWVudGN1c3RvbWVyYWRkcmVzc2lkIjoiIiwianRpIjoiYzE0MzEzZTQtNGZhOC00ZGJlLTgxMTUtYjdmNzI0ZjQyYTlmIiwiZXhwIjoxNzEzOTUyMzY0LCJpc3MiOiJUZXN0LmNvbSIsImF1ZCI6IlRlc3QuY29tIn0.DL_m-VqqJS5IbVgAd_PUbcOCptZwpXpeezhEKbh3kmQ"; // Replace with your actual API token
        config.headers['Authorization'] = `Bearer ${apiKey}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



