// //For US
export const CONFIG = {
  // API_URL: 'https://explorer.aggregateintelligence.com/self-storage/api/',
  // MIGSUITE_API_URL: 'https://bi.aggregateintelligence.com/api/',
  API_URL: "https://reports.stortrack.com/api/",
  MIGSUITE_PRICING_URL: "https://bi.aggregateintelligence.com/pricing/",
  Explorer_url: "https://www.stortrack.com/products/explorer.html",
  NASS_api_url: "https://explorer.aggregateintelligence.com/self-storage",
  BIG_api_url: "https://bi.aggregateintelligence.com",
  Us_pdf: "https://www.stortrack.com/pdf/MarketReport_Sample_US.pdf",
  Canada_pdf: "https://www.stortrack.com/pdf/MarketReport_Sample_Canada.pdf",
  Currency_Symbol: "$",
  MinimumHistoricalReportPrice: 5,
  Contact_Support: "1-800-969-7424",
  Mail_Support: "support@stortrack.com",
  US_URL: "https://www.stortrack.com/",
  AU_URL: "https://au.stortrack.com/",
  UK_URL: "https://www.stortrack.co.uk/",
  NZ_URL: "https://nz.stortrack.com/",
  STK_URL: "https://www.stortrack.com/",
  LSS_Home: "http://www.listselfstorage.com",
  Application_URL: "https://reports.stortrack.com",
  REPORTS_URL: "https://reports.stortrack.com/market-reports/",
  Region_Id: 1,
  Signin_url: "https://www.stortrack.com/signin.html",
  Product_historical_url: "https://reports.stortrack.com/historical-reports/",
  Product_market_url: "https://reports.stortrack.com/market-reports/",
};
