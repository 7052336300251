import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { CONFIG } from '../../utils/config';
import { handlingCatchErorr } from '../../utils/errorHandling';
import { useParams, useLocation } from 'react-router';
import { api } from '../../services/api';
import { downloadStatus, reportType, reportTypeId } from '../../utils/enum';
import loaderDownload from "../../assets/Images/loader-download.gif";

const ReportDownload = () => {
    const { referenceId } = useParams();
    const [loader, setLoader] = useState(false);
    const [OrderLoader, setOrderLoader] = useState(false);
    const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    const url =window.location.href ;
    const secureId = url.split("sec_id=")[1]
    const [compIds, setCompId] = useState({
        comp1Id: {},
        comp2Id: {},
    });
    const [reportLoader, setReportLoader] = useState(false);
    const [marketId, setMarketId] = useState(0);
    const [orderDetails, setOrderDetails] = useState([]);
    const [linkLoader, setLinkLoader] = useState(false);
    const [searchTypeId,setSearchTypeId] = useState("")

    useEffect(() => {
        handleOrder();
        handleValidate();
    }, [])

   

    const handleOrder = (isDownload) => {
        setOrderLoader(true)
        const handleOrders = () => {
        const inputs = ({
            "secureid": secureId.toString(),
            "referenceid": referenceId.toString()
        })

        api.post("/MarketReport/orders", inputs)
            .then((res) => {
                const data = res.data;
                const searchType = Object.entries(data).length > 0 && data.order.searchTypeId;
                setSearchTypeId(searchType)
                setOrderDetails(data)
                setOrderLoader(false);
                if (Object.keys(data).length > 0 && ( !data.download.downloadUrl )) {
                    setLinkLoader(true);
                    if(isDownload && isDownload === "success") {
                        setTimeout(handleOrders, 30000);
                    }
                  
                } else {
                    setLinkLoader(false);
                 
                }
                

            })
            .catch((err) => {
                handlingCatchErorr(err)
                setOrderLoader(false)
                setLinkLoader(false);
            })
        };    
        handleOrders();
    }

    const handleValidate = () => {
        setLoader(true)
        const inputs = ({
            "secureid": secureId.toString(),
            "referenceid": referenceId.toString()
        })
        api.post("/MarketReport/validate", inputs)
            .then((res) => {
                setLoader(false)
                if (res.data === "success") {
                    handleAdd();
                }

            })
            .catch((err) => {
                handlingCatchErorr(err)
                setLoader(false)
                setLinkLoader(false);
            })
    }

    const handleAdd = () => {
        setLoader(true)
        const inputs = ({
            "secureId": secureId.toString(),
            "referenceId": referenceId.toString()
        })
        api.post("/MarketReport/add", inputs)
            .then((res) => {
                if (res.data.result === "Success") {
                    const marketId = res.data.marketId;
                    if (marketId) {
                        handleComparision(marketId, res.data.addMarket, referenceId);
                    }
                    setMarketId(res.data.marketId);

                }
                setLoader(false)

            })
            .catch((err) => {
                handlingCatchErorr(err)
                setLoader(false)
                setLinkLoader(false);
            })
    }

    const handleComparision = (marketId, addMarket, referenceId) => {
        setLoader(true)
        const inputs = {
            "referenceid": referenceId.toString(),
            "secureid": secureId.toString(),
            "marketId": marketId,
            "historicalReportId": 0
        }
        api.post("/MarketReport/comparision", inputs)
            .then((res) => {
                let palettecomparision = res.data;
                let comp1Id = palettecomparision.filter(
                    (comp) => comp.widgetcomparetypeid === 1
                )[0];
                let comp2Id = palettecomparision.filter(
                    (comp) => comp.widgetcomparetypeid === 2
                )[0];
                setCompId({
                    ...compIds,
                    comp1Id: {
                        ...compIds.comp1Id,
                        comp1Id,
                    },
                    comp2Id: {
                        ...compIds.comp2Id,
                        comp2Id,
                    },
                });
                exportMarketToPDF(comp1Id, comp2Id, marketId, 2);
                setLoader(false)
            })
            .catch((err) => {
                handlingCatchErorr(err)
                setLoader(false)
                setLinkLoader(false);
            })
    }



    const exportMarketToPDF = (comp1Id, comp2Id, marketID, type) => {
        setReportLoader(true);
        let usrStId = marketID ? marketID : marketId;

        //construct time stamp for pdf report filenmae
        let currentDate = new Date();
        let day = String(currentDate.getDate()).padStart(2, '0'); // Get the day with leading zero if necessary
        let month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Get the month with leading zero if necessary
        let year = currentDate.getFullYear();
        let time = currentDate.getTime();

        let timestamp = `${day}${month}${year}${time}`;
        //construct filename for pdf report.
        let fileName =
            usrStId.toString() + "_StorTrackMarketReport_" + timestamp + ".pdf";
        let pdfName;
        if (type === 2) {
            pdfName =
                "Your market report generation is in progress and we will email it to you when ready. Please check your email to download the report.";
        } else {
            pdfName =
                "Your download is being processed. The " +
                fileName +
                " file will download automatically to your browser when ready. Please do not close or refresh the window until the download is complete. We recommend you check your Downloads folder if you don’t see it in your browser.";
        }

        let c1Id = type === 2 ? comp1Id.id : compIds.comp1Id.comp1Id.id;
        let c2Id = type === 2 ? comp2Id.id : compIds.comp2Id.comp2Id.id;

        const inputs =
        {
            "marketID": usrStId,
            "templateID": 1,
            "competitor1ID": c1Id,
            "competitor2ID": c2Id,
            "fileName": fileName,
            // "priceHistorybyStoreId1": 0,
            // "priceHistorybyStoreId2": 0,
            // "priceHistorybyStoreId3": 0,
            "isMyMarket1": 0,
            "isMyMarket2": 0,
            // "storelist": [
            //     "string"
            // ],
            "isDownload": type,
            // "email": "string",
            // "downloadLink": "string",
            // "firstName": "string",
            // "lastName": "string",
            // "stWebsiteURLShortener": "string",
            // "hostName": "string",
            // "domain": "string",
            // "infoEmail": "string",
            // "contactNo": "string",
            "isIncludeEstimatedSQFT": true,
            "secureId": secureId.toString()
        }

        api.post("/MarketReport/downloadfile", inputs)
            .then(response => {
                const data = response ;
                if(data){
                    setTimeout(() => {
                        handleOrder("success");
                    }, 2000);                }
              
                setReportLoader(false);
                
            })
            .catch((err) => {
               
                setLinkLoader(false);
                setReportLoader(false);
                handlingCatchErorr(err);
            });


    };

    return (
        <>
            {(loader || OrderLoader) && <div className="loader-wrap"><div className="loading"></div> : </div>}
            <div className="last-step">
                <Container>
                    <Row>
                        <Col md="12">
                            <div className="sec-msg">
                                <Paper className="p-3 elevation0" component="div" align="center" >
                                    <Typography variant="h3" component="h3" className="pb-2 thank-you" >
                                        Thank you for your purchase!
                                    </Typography>
                                    <Typography variant="h6" component="h6">
                                        You will get an order confirmation email shortly.
                                    </Typography>
                                    <Typography variant="body2" className="fin-txt">
                                        <p style={{ marginBottom: '5px' }}>Market Reports may take up to several minutes to generate. When ready, click on the Download Link to view the PDF report.</p>
                                        <p>If you need to close this window, you can re-open it by clicking the link in the email confirmation sent to you.</p>
                                    </Typography>
                                    {Object.entries(orderDetails).length > 0 && orderDetails.activationLink 
                                        ? <Typography variant="body2" className="fin-txt">
                                            <p style={{ marginBottom: '5px' }}>To access your account, please click here to set up  <a href={orderDetails.activationLink} className="thankyou-act-btn" target="_blank"> Activate</a></p>
                                        </Typography>
                                        : ""
                                    }
                                    <div className="promoCode_btn pt-2 pb-0">
                                        <Table className="table table-bordered custom-table payment-table" style={{ marginBottom: '10px' }}>
                                            <TableHead>
                                                <TableRow className="table-header">
                                                    <TableCell>Report Type</TableCell>
                                                    <TableCell>Market</TableCell>
                                                    {searchTypeId === 1 ?
                                                    <TableCell>Details</TableCell>
                                                    :""}
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Download link</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            
                                                {Object.keys(orderDetails).length > 0 ?

                                                    <TableRow >
                                                        <TableCell>{orderDetails.order.reporttypeId === reportTypeId.marketReport ? reportType.marketReport : ""}</TableCell>
                                                        <TableCell>
                                                            {searchTypeId === 1 ?
                                                            (!orderDetails.order.address ? " " : orderDetails.order.address + "," + " ")
                                                         + (!orderDetails.order.city ? "" : orderDetails.order.city + ", " + " ")
                                                         + (!orderDetails.order.state ? "" : orderDetails.order.state  + " ") + 
                                                         (!orderDetails.order.zipCode ? "" : orderDetails.order.zipCode)
                                                        : (!orderDetails.order.city ? "" : orderDetails.order.city + ", " + " ")
                                                        + (!orderDetails.order.state ? "" : orderDetails.order.state  + " ")
                                                        }
                                                         </TableCell>
                                                         {searchTypeId === 1 ?
                                                        <TableCell>{orderDetails.order.radius} miles radius</TableCell>
                                                        : ""}
                                                        <TableCell>
                                                            {orderDetails.download.downloadStatusId === downloadStatus.notStarted ? "In Progress "
                                                                : orderDetails.download.downloadStatusId === downloadStatus.inprogress ? "In Progress" :
                                                                    orderDetails.download.downloadStatusId === downloadStatus.success ? "Success" :
                                                                        orderDetails.download.downloadStatusId === downloadStatus.failure ? "Failure" :
                                                                            orderDetails.download.downloadStatusId === downloadStatus.removed ? "Removed" :
                                                                                orderDetails.download.downloadStatusId === downloadStatus.delivered ? "Delivered" : 
                                                                                orderDetails.download.downloadUrl ? "Success" : "--"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {orderDetails.download.downloadStatusId === downloadStatus.success && orderDetails.download.downloadUrl ?
                                                                <a href={orderDetails.download.downloadUrl} target='_blank' > Click Here </a>
                                                                : linkLoader ? <img src={loaderDownload} alt="wait" /> : "--"
                                                            }
                                                        </TableCell>
                                                    </TableRow>

                                                    :
                                                    (!OrderLoader &&
                                                        <TableRow>
                                                            <TableCell colSpan={5} className="no-data cart-detailsnotavailable" >{'Details not available'}</TableCell>
                                                        </TableRow>)
                                                }
                                            </TableBody>


                                        </Table>
                                    </div>
                                    <Typography component="p" className="pt-2 mb-0" style={{ paddingBottom: '15px', textAlign: 'left', fontSize: '14px' }} gutterBottom>
                                        <b style={{ display: 'block', marginBottom: '5px' }}>Don’t Forget! Your purchase also includes access to the Explorer platform</b> for 30 days where you can access additional market information For access, click below to sign in!
                                    </Typography>
                                    <Typography component="p" gutterBottom classname="fnt-14">
                                        <a href={CONFIG.Explorer_url} target="_blank" class="all-reports sample-reports btn btn-secondary go-to-mvp">Go to my Explorer Account</a>
                                    </Typography>
                                    <Divider />
                                    <Typography component="p" className="pt-2 mb-0 fnt-14 opacity-7" gutterBottom>
                                        Questions? Contact us at {CONFIG.Contact_Support} or
                                    </Typography>
                                    <Typography component="p" gutterBottom classname="fnt-14">
                                        <a href={`mailto:${CONFIG.Mail_Support}`} className="email-conform">{CONFIG.Mail_Support}</a>
                                    </Typography>

                                </Paper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
export default ReportDownload;