export const ReportTypeId = {
    MarketReport: 1,
    HistoricalReport: 2
}
export const countryId = [
    { id: 1, name: "Canada", code: "CA" },
    { id: 2, name: "United States", code: "USA" },
    { id: 3, name: "Austria", code: "AT" },
    { id: 4, name: "Germany", code: "DE" },
    { id: 5, name: "Switzerland", code: "CH"},
    { id: 6, name: "Australia", code: "AU" },
    { id: 7, name: "United Kingdom", code: "UK" },
    { id: 8, name: "Scotland", code: "Scotland" },
    { id: 9, name: "Northern Ireland", code: "Northern Ireland" },
    { id: 10, name: "Wales", code: "Wales" },
    { id: 11, name: "New Zealand", code: "NZ" },
]

export const commonYears = {
    Canada: 2021,
    FirstYear: 2022,
    SecondYear: 2024,
    ThridYear: 2026,
    FourthYear: 2028,
}

export const Country_Id = {
    US: 2,
    CA: 1,
};

export const subscriptionKey = {
    marketExplorer: "us-market-explorer",
    historicalReport: "us-historical-report"
};

export const lookupKey = {
    marketExplorer: "na-ss-report-1",
    historicalReport : "us-historical-data"
};

export const units = {
    marketExplorer: 1,
};

export const Regions = {
    US : 1,
    AU : 2,
    UK : 3,
    NZ : 4

} 

export const length = {
    name: 30,
    email: 50,
   
}
export const minimumLength = {
    minLength: 3,
}

export const reportTypeId = {
    marketReport:1,
    historicalReport:2,
}

export const reportType = {
    marketReport:"Market Report"	,
    historicalReport:"Historical Report",
}

export const downloadStatus = {
    notStarted : 1,
    inprogress : 2,
    success : 3 ,
    failure : 4,
    removed : 5,
    delivered : 6
}