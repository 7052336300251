import { length, minimumLength } from "../../utils/enum";

export const validationFunction = (name,value) =>{
    if (name === "search-address") {
        if(value.length === 0 ){
            return 'Please select the address'
        }
        else {
            return ""
        }
        }

        if (name === "fName" ) {
            if (value.length === 0) return 'First Name is required';
            else if (value.length < minimumLength.minLength) return `First Name must be between ${minimumLength.minLength} and  ${length.name} characters. `;
            else if (value.length > length.name) return `First Name cannot exceed  ${length.name} characters. `;
            else if (!(/^[A-Za-z0-9\s.'_-]+$/).test(value)) return 'Invalid character';
            else return '';
        }
    
        if (name === "lName") {
            if (value.length === 0) return 'Last Name is required';
            else if (value.length < minimumLength.minLength) return `Last Name must be between ${minimumLength.minLength} and  ${length.name} characters. `;
            else if (value.length > length.name) return `Last Name cannot exceed  ${length.name} characters. `;
            else if (!(/^[A-Za-z0-9\s.'_-]+$/).test(value)) return 'Invalid character';
            else return '';
        }
       
        if (name === "email" ) {
            if (value.length === 0) return 'Email is required';
            else if (!/^[a-zA-Z0-9]+(?:[._%+-][a-zA-Z0-9]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,4}$/.test(value)) return 'Invalid email address';
            else if (value.length > length.email) return `UserName cannot exceed ${length.email} characters `;
            else return '';
        }
        if (name === "phone" ) {
        
            var string = value;
            var replacedString = value.replace(/-/g, "").replace("(", "").replace(")", "");
            var spaceRemovedStr = string.replace(/ /g, '');
            var numericRegex = /^([0-9]*)$/;
            var valid = numericRegex.exec(replacedString);
            let finalData = '';
            if (valid) {
                string = replacedString;
                var regex = replacedString.length === 10 ? /^([^\s]{3})([^\s]{3})+([^\s]{4})+$/g : replacedString.length === 11 ? /^([^\s]{3})([^\s]{3})+([^\s]{5})+$/g : replacedString.length === 12 ? /^([^\s]{3})([^\s]{3})+([^\s]{6})+$/g : replacedString.length === 13 ? /^([^\s]{3})([^\s]{3})+([^\s]{7})+$/g : replacedString.length === 14 ? /^([^\s]{3})([^\s]{3})+([^\s]{8})+$/g : replacedString.length === 15 ? /^([^\s]{3})([^\s]{3})+([^\s]{9})+$/g : /^([^\s]{3})([^\s]{3})+([^\s]{9})+$/g;
                var match = regex.exec(string);
                if (match) {
                    match.shift();
                    match[0] = "(" + match[0] + ")";
                    string = match.join("-");
                    if (replacedString.length >= 16) {
                        finalData = 'Invalid number';
                    } else {
                        finalData = '';
                    }
                } else {
                    if (replacedString.length >= 16) {
                        finalData = 'Invalid number';
                    } else {
                        finalData = '';
                    }
                }
    
            } else if (spaceRemovedStr.length > 0) {
                finalData = 'Invalid number';
            }
            if (name === 'phone' && string === "") finalData = 'Phone is required';
            else if (string === "") finalData = '';
            return { 'phoneData': string, 'errMsg': finalData };
        }
        if (name === "company" ) return "";

}