import { Typography, Button, } from '@mui/joy';
import { useEffect, useRef, useState } from 'react';
import { Grid, TextField, Box, Modal } from '@mui/material';
import { api, apiBIG } from '../../services/api';
import { lookupKey, units } from "../../utils/enum";
import { validationFunction } from '../CommonFunction/validation';
import { handlingCatchErorr } from '../../utils/errorHandling';
import { CONFIG } from '../../utils/config';
import ModalClose from '@mui/joy/ModalClose';

let textFieldVariables = {

    "Account Details": {
        "First Name": "fName", "Last Name": "lName", "Company": "company", "Phone": "phone", "Email Address": "email"
    }
};
let widthValues = { "email": 12, "fName": 6, "lName": 6, "company": 12, "phone": 12, }
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const PurchaseDetails = ({ addressDetails, radiusDetails, names, buttonName, isDisable,searchId }) => {
    const isFirstRender = useRef(true);
    let initialFormData = { fName: '', lName: '', company: '', phone: '', email: '' };
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ ...initialFormData });
    const [isNextStep, setIsNextStep] = useState(false);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isNextStep) handleAccountPostSubmit();
    }, [errors])

    const errorsHandle = (errorMessage) => {
        if(errorMessage){
            window.globalThis.REACT_SNACK_OPEN = true;
            window.globalThis.REACT_SNACK_MESSAGE = errorMessage;
            window.globalThis.REACT_SNACK_COLOR = "info";
            document.getElementById('enable-error-snack').click();
        }
       
    }

    const handleChange = (e) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const { name } = e.target;
        const value = e.target.value;
        let returnedInfo = validationFunction(name, value);
        if (name === "phone") setFormData((prevData) => ({ ...prevData, [name]: returnedInfo?.phoneData }));
        else setFormData((prevData) => ({ ...prevData, [name]: value }));
        errorMessageFunc(name, returnedInfo);
    };
    const errorMessageFunc = (errorName, errorMessage) => {
        if (errorName === "phone") setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage.errMsg }));
        else setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }

    const handleRefereneApi = () => {
        const addressInfo = {
            "address": addressDetails?.address,
            "city": addressDetails?.city,
            "state": addressDetails?.state,
            "zipcode": addressDetails?.zipcode,
            "countryid": addressDetails?.countryid,
            "latitude": addressDetails?.latitude,
            "longitude": addressDetails?.longitude,
            "searchtypeid": searchId,
            ...(searchId === 1 ? {"radius": radiusDetails} : ""),
            

        }
        api.post("/MarketReport/reference", addressInfo)
            .then((res) => {
                setIsLoader(false)
                handlePurchaseApi(res.data)

            })
            .catch((err) => {
                setIsLoader(false)
                handlingCatchErorr(err)
            });
    }

    const handlePurchaseApi = (refId) => {
        setLoader(true)
        const userDetails = formData;
        const userpurchaseInfo = {
            "login": userDetails.email,
            "firstName": userDetails.fName,
            "lastName": userDetails.lName,
            "company": userDetails.company,
            "phone": userDetails.phone,
            "LookupKey": lookupKey.marketExplorer,
            "Units": units.marketExplorer,
            "Credits": 0,
            "ReferenceId": refId.toString(),
            "ReferenceUrl": `${CONFIG.Application_URL}/market-report/download/${refId}?${"sec_id="}`,
            "SecureId": "",
            "ProductUrl": CONFIG.Product_market_url
        }

        apiBIG.post("/purchase", userpurchaseInfo)
            .then((res) => {

                const url = res.data.url
                setLoader(false)
                if (url) {
                    setLoader(true)
                    window.location.replace(url);

                }
            })
            .catch((err) => {
                setLoader(false)
                handlingCatchErorr(err)
            });
    }



    const handleAccountPostSubmit = () => {

        let areThereErrorMsgOrNot = Object.entries(errors).length !== 0 ? Object.entries(errors).every(([key, val]) => val === "") ? true : false : true;
        if (areThereErrorMsgOrNot) {
            setIsLoader(true)
            handleRefereneApi();
        }
    }

    const handleAccountPreSubmit = () => {
        setIsNextStep(true);
        Object.entries(formData).map(([key, val]) => {
            let returnedErrors = validationFunction(key, val, formData)
            errorMessageFunc(key, returnedErrors);
            return '';
        });
    }
    const handleOpen = () => {
        if (isDisable === "disable" ||  isDisable === "summary-disable") {
            errorsHandle("We regret to inform you that the report is unavailable due to no self-storage facilities in the specified location")
            return; // Prevent the click action if disabled
            
          }
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setErrors({})
        setIsNextStep(false);
        setFormData(initialFormData);
    }

    return (
        <>
            {(loader || isLoader) && <div className="loader-wrap"><div className="loading"></div> : </div>}
            <div onClick={handleOpen} className={names === "link" ? "buyreport" : "purchase-report"}>{buttonName}</div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Box sx={style} className='purchasereport-model-popup'>
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={handleClose} />
                    {Object.entries(textFieldVariables).map(([sectionTitle, sectionFields]) => (
                        <div key={sectionTitle}>
                            <Typography
                                component="h2"
                                id="modal-title"
                                level="h4"
                                textColor="inherit"
                                fontWeight="lg"
                                mb={1}
                            >
                                {sectionTitle}
                            </Typography>
                            <form className='custom-form popupform'>
                                <Grid container spacing={3}>
                                    {Object.entries(sectionFields).map(([key, val]) => (
                                        <Grid key={key} item xs={widthValues[val]}>
                                            <TextField
                                                name={val}
                                                label={key}
                                                type={val === "email" ? "email" : "text"}
                                                value={formData && formData[val]}
                                                onChange={handleChange}
                                                fullWidth
                                                error={val !== "company" ? !!errors[val] : false}
                                                helperText={val !== "company" ? errors[val] : false}
                                                required={val !== "company" ? true : false}
                                                className='custom-fields'
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        </div>
                    ))}
                    <Grid item xs={12} className='button-alignment popup-purchasereport'>
                        <Button variant="contained" type="button" onClick={handleClose} className='success-btn'>Cancel</Button>
                        <Button variant="contained" type="button" onClick={handleAccountPreSubmit} className='success-btn-purchase'>Purchase</Button>
                    </Grid>

                </Box>
            </Modal>
        </>
    );

}

export default PurchaseDetails;