import { Button, Modal, Paper, Typography } from "@mui/material";
import React from "react";

const SessionTimeoutModal = ({ open, countdown, onLogout, onContinue }) => {
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        className="custom-model-popup timeout-popup"
      >
        <Paper          
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
          }}
        >
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            style={{ marginBottom: '7px' }}
          >
            Session Timeout
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary" mb={3}>
           The current session is about to expire in <span style={{color: '#f15d47', fontWeight: '500'}}>{countdown}{" "}
            seconds</span> due to inactivity.
          </Typography>
          <Button onClick={onLogout} className="border-btn session-btn" >
            Logout
          </Button>
          <Button onClick={onContinue} className="success-btn session-btn" >
            Continue Session
          </Button>
        </Paper>
      </Modal>
    </>
  );
};
export default SessionTimeoutModal;