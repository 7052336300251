import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Slider, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography,} from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { CONFIG } from "../../utils/config";
import NumberFormat from "react-number-format";
import axios from "axios";
import { ReportTypeId, countryId, subscriptionKey } from "../../utils/enum";
import { api, apiBIG } from "../../services/api";
import ReportHome from "../CommonComponent/ReportHome/ReportHome";
import { validationFunction } from "../CommonFunction/validation";
import HistoricalPurchaseDetails from "./HistoricalPurchaseDetails";
import loaderDownload from "../../assets/Images/loader-download.gif";
const HistoricalReports = (props) => {
  const hiscrollRef = useRef();
  const [showSection, setShowSection] = useState(false);
  const [showresultSection, setShowResultSection] = useState(false);
  const [resetBtn, setResetBtn] = useState(false);
  const radiusLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [radiusValue, setRadiusValue] = useState(5);
  const [searchInput, setSearchInput] = useState("");
  const [searchSuggestion, setSearchSuggestion] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [historicalData, setHistoricalData] = useState([]);
  const [isSelectedStores, setIsSelectedStores] = useState(false);
  const [sortUpdateProximity, setSortUpdateProximity] = useState(true);
  const [showProxSort, setShowProxSort] = useState(true);
  const [selYrsData, setSelYrsData] = useState(1);
  const [infoData, setInfoData] = useState([]);
  const [checkedStores, setCheckedStores] = useState([]);
  const [trackedIds, setTrackedIds] = useState([]);
  const [dateSince, setDateSince] = useState(new Date());
  const [availableDate, setAvailableDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [totalPrice, setTotalPrice] = useState(null);
  const [actualPrice, setActualPrice] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [hisDataPurchase, setHisDataPurchase] = useState({});
  const [hisDiscountGrid, setHisDiscountGrid] = useState([]);
  const [storeCounts, setStoreCounts] = useState(0);
  const [isFinalMsg, setIsFinalMsg] = useState(false);
  const [showText, setShowText] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);  
   const [enableLoader, setEnableLoader] = useState(false)
  const [address, setAddress] = useState('');
  const [errors, setErrors] = useState({});
  const [priceValue, setPriceValue] = useState("")
  const [validationMessage, setValidationMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [searchTypeId, setSearchTypeId] = useState(1);
  const [searchType, setSearchType] = useState(1);
  const [triggerGetPrice, setTriggerGetPrice] = useState(false);
  const [marketSummryData, setMarketSummryData] = useState({
    latitude: null,
    longitude: null,
    cityName: null,
    stateName: null,
    zipCode: null,
    ctryId: null,
    address: null,
    storeId: null
});
  //Search Functionality
  const handleSearch = async (e) => {
    setSubmitted(false)
    setErrors({})
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    if (inputValue.trim() === '') {
      setSearchSuggestion([]);
      return;
    }
      mapSearchAddress(inputValue);
   
  }
  const mapSearchAddress = async (inputValue) => {
    const accessToken = 'pk.eyJ1IjoiY2Vuc3RyYWNrIiwiYSI6ImNrbHVweHQxNDJpdncybmtkOHRpZzZ1M2YifQ.5wzmtoTYFiS01eAy1LAI1Q';
    const country = "us,ca";
    const language = 'en';
    const limit = 10;
    const types = 'address,place,locality,postcode'; // Comma-separated list of types
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(inputValue)}.json?country=${country}&limit=${limit}&types=${types}&language=${language}&access_token=${accessToken}`;
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch suggestions');
      }
      const data = await response.json();
      const features = data.features;
      setSearchSuggestion(features);
    } catch (error) {
      setSearchSuggestion([])
      console.error('Error fetching suggestions:', error.message);
    }
  }
  //Search Functionality
  const yearsOfData = [
    { id: 1, name: "All available data" },
    { id: 2, name: "Custom selection" },
  ];
  const marketSearch = [
    { id: 1, name: "Radius" },
    { id: 3, name: "City" },
  ];;
  const handleChangeMarket = (e) => {
    const id = parseInt(e.target.value);
    setSearchTypeId(id);
    setResetBtn(true)
    getHistoricalData("", "", marketSummryData, id)
  };
  let ShowProxSort = true;
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#5c5c5c",
      maxWidth: 550,
    },
  }))(Tooltip);
  // Function to get historical data
  const getHistoricalData = (value,  scroll, dataValue, id) => {
    const summaryInput = scroll === "radius" ? marketSummryData : dataValue
    const { city, state, latitude, longitude } = marketSummryData;
    setEnableLoader(true);
    const sectionId = "historical-summary-scroll";
    const lat = summaryInput.latitude !== undefined ? summaryInput.latitude : latitude;
    const long = summaryInput.longitude !== undefined ? summaryInput.longitude : longitude;
    const cityName = summaryInput.cityName !== undefined ? summaryInput.cityName : city;
    const stateName = summaryInput.stateName !== undefined ? summaryInput.stateName : state;
    const ctryId = summaryInput.ctryId;
    let input = {
        "searchTypeId":id ?id : searchTypeId,
        "latitude": lat,
        "longitude": long,
        "radius":  value ? value : radiusValue,
        "polygonArea": "",
        "city": cityName,
        "state": stateName,
        "countryId": ctryId,
        "resultSetNumber": 1,
        "marketId": null
    }
    api.post(CONFIG.API_URL + "HistoricalReport/summary", input)
      .then((res) => {
        const historicalData = res.data;
        if (historicalData && historicalData.length > 0) {
          setShowSection(true);
          let dates = historicalData.map(data => new Date(data.collected_since));
          var year = new Date().getFullYear();
          var month = new Date().getMonth();
          var day = new Date().getDate();
          var date = new Date(year - 1, month, day);
          setHistoricalData(historicalData);
          setDateSince(date);
          setStartDate(date);
          onLoadDataSelect(historicalData);
          setShowResultSection(false)
          // Scroll to the results section
          if (scroll === "isScroll") {
            const section = document.getElementById(sectionId);
            if (section) {
                const sectionPosition = section.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: sectionPosition,
                    behavior: 'smooth'
                });
            }
        }                
        } else {
          setShowSection(true);
          setShowResultSection(true)
          setHistoricalData([]);
          setTrackedIds([])
        }
        setEnableLoader(false);
      })
      .catch((err) => {
        setEnableLoader(false);
        setShowSection(false);
      });
  };   
  const resetValues = () => {
    setRadiusValue(5);
    setHistoricalData([])
    setShowSection(false)
    setShowResultSection(false);
    setSearchTypeId(1)
    setResetBtn(false)
  };
  const updateRadiusValue = (e, value) => {
    let returnedErrors = validationFunction("search-address", address);
    errorMessageFunc("search-address", returnedErrors);
    setResetBtn(true)
    const hasErrors = Object.values(returnedErrors).some(error => error !== "");
    if (!hasErrors) {
        setRadiusValue(value);
        getHistoricalData(value,marketSummryData,"radius")
    }
  }
  const checkTrackedStores = () => {
    let newCheckedStores = [];
    let newTrackedIds = [];
    historicalData.forEach((hisData) => {
      let checkFrDate =
        moment(new Date(hisData.collected_since), "DD/MM/YYYY") >=
        moment(new Date(dateSince), "DD/MM/YYYY") ||
        moment(new Date(hisData.collected_till), "DD/MM/YYYY") <=
        moment(new Date(availableDate), "DD/MM/YYYY") ||
        moment(new Date(dateSince), "DD/MM/YYYY") >=
        moment(new Date(hisData.collected_since), "DD/MM/YYYY") ||
        moment(new Date(availableDate), "DD/MM/YYYY") <=
        moment(new Date(hisData.collected_till), "DD/MM/YYYY");
      let checkToDate =
        moment(new Date(dateSince), "DD/MM/YYYY") <
        moment(new Date(availableDate), "DD/MM/YYYY") &&
        moment(new Date(dateSince), "DD/MM/YYYY") <=
        moment(new Date(hisData.collected_till), "DD/MM/YYYY");
      let cmpDate = checkToDate && checkFrDate;
      let isHide = cmpDate
        ? moment(new Date(hisData.collected_since), "DD/MM/YYYY") <=
          moment(new Date(availableDate), "DD/MM/YYYY")
        : cmpDate;
      let check = trackedIds.filter((x) => x === hisData.storeid);
      if (isHide && check.length > 0) {
        newTrackedIds.push(hisData.storeid);
        newCheckedStores.push(hisData.storeid + "|1");
      }
    });
    setTrackedIds(newTrackedIds);
    setCheckedStores(newCheckedStores);
    setIsSelectedStores(newTrackedIds.length > 0 ? newTrackedIds.length === storeCounts : false);
  };
  const getStoreCounts = (type) => {
    let count = 0;
    let countInc = 0;
    historicalData.forEach((hisData) => {
      const collectedSince = moment(new Date(hisData.collected_since), "DD/MM/YYYY");
      const collectedTill = moment(new Date(hisData.collected_till), "DD/MM/YYYY");
      const sinceDate = moment(new Date(dateSince), "DD/MM/YYYY");
      const availableTillDate = moment(new Date(availableDate), "DD/MM/YYYY");
      const checkFrDate = collectedSince >= sinceDate || collectedTill <= availableTillDate || sinceDate >= collectedSince || availableTillDate <= collectedTill;
      const checkToDate = sinceDate < availableTillDate && sinceDate <= collectedTill;
      const cmpDate = checkToDate && checkFrDate;
      const isHide = cmpDate ? collectedSince <= availableTillDate : cmpDate;
      if (isHide) {
        count++;
      } else {
        countInc++;
      }
    });
    setStoreCounts(selYrsData === 1 ? historicalData.length : count);
    setShowText(countInc > 0);

    if (parseInt(selYrsData) === 2 && type === 1) {
      checkTrackedStores();
    }
  };
  const forceUpdate = () => {
    setUpdateFlag(prevFlag => !prevFlag);
  };
  const handleChange = (e) => {
    if (e.target.name === "yearsOfData") {
      let yearValue = parseInt(e.target.value);
      setSelYrsData(yearValue);
      setDateSince(yearValue === 1 ? startDate : dateSince);
      setAvailableDate(yearValue === 1 ? endDate : availableDate);
      setStoreCounts(0);
      if (trackedIds.length > 0) {
        getStoreCounts(1);
        // getPrice();
      } else {
        getStoreCounts(0);
      }
    }
    setTriggerGetPrice(prev => !prev);
  };
  const handleSDateChange = (date) => {
    if (date.toString() !== "Invalid Date") {
      const toDate = new Date(availableDate);
      if (toDate.toString() !== "Invalid Date" && date > toDate) {
          setValidationMessage("From date cannot be later than To date");
          return;
      } else {
          setValidationMessage('');
      }
      setDateSince(date);
      setStoreCounts(0);

      if (trackedIds && trackedIds.length > 0) {
        getStoreCounts(1);
        getPrice();
      } else {
        getStoreCounts(0);
      }
    }
  };
  const handleLDateChange = (date) => {
    if (date.toString() !== "Invalid Date") {
      const fromDate = new Date(dateSince);
      if (date < fromDate) {
        setValidationMessage("To date must be greater than From date");
        return;
      } else {
        setValidationMessage('');
      }  
      setAvailableDate(date);
      setStoreCounts(0);
      if (trackedIds && trackedIds.length > 0) {
        getStoreCounts(1);
        getPrice();
      } else {
        getStoreCounts(0);
      }
    }
  };
  const onLoadDataSelect = (historicalData) => {
    let trackedIds = [];
    let checkedStores = [];
    let isSelectedStores = false;
    if (historicalData.length > 0) {
      if (selYrsData === 1) {
        historicalData.forEach((e) => {
          trackedIds.push(e.storeid);
          checkedStores.push(e.storeid + "|1");
        });
        isSelectedStores = true;
      } else {
        historicalData.forEach((hisData) => {
          let checkFrDate =
            moment(new Date(hisData.collected_since), "DD/MM/YYYY") >=
              moment(new Date(dateSince), "DD/MM/YYYY") ||
            moment(new Date(hisData.collected_till), "DD/MM/YYYY") <=
              moment(new Date(availableDate), "DD/MM/YYYY") ||
            moment(new Date(dateSince), "DD/MM/YYYY") >=
              moment(new Date(hisData.collected_since), "DD/MM/YYYY") ||
            moment(new Date(availableDate), "DD/MM/YYYY") <=
              moment(new Date(hisData.collected_till), "DD/MM/YYYY");
          let checkToDate =
            moment(new Date(dateSince), "DD/MM/YYYY") <
              moment(new Date(availableDate), "DD/MM/YYYY") &&
            moment(new Date(dateSince), "DD/MM/YYYY") <=
              moment(new Date(hisData.collected_till), "DD/MM/YYYY");
          let cmpDate = checkToDate && checkFrDate;
          let isHide = cmpDate
            ? moment(new Date(hisData.collected_since), "DD/MM/YYYY") <=
                moment(new Date(availableDate), "DD/MM/YYYY")
            : cmpDate;
          if (isHide) {
            trackedIds.push(hisData.storeid);
            checkedStores.push(hisData.storeid + "|1");
          }
        });
        isSelectedStores = trackedIds.length > 0;
      }
  
      setTrackedIds(trackedIds);
      setCheckedStores(checkedStores);
      setIsSelectedStores(isSelectedStores);
  
      if (trackedIds.length > 0) {
        // getPrice(trackedIds);
      }
    }
  };
  // SelectAllStores function
  const SelectAllStores = (e) => {
    let newTrackedIds = [];
    let newCheckedStores = [];
    let newIsSelectedStores = false;
    if (e.target.checked) {
      if (selYrsData === 1) {
        historicalData.forEach((e) => {
          newTrackedIds.push(e.storeid);
          newCheckedStores.push(e.storeid + "|1");
        });
        newIsSelectedStores = true;
      } else {
        historicalData.forEach((hisData) => {
          let checkFrDate =
            moment(new Date(hisData.collected_since), "DD/MM/YYYY") >=
              moment(new Date(dateSince), "DD/MM/YYYY") ||
            moment(new Date(hisData.collected_till), "DD/MM/YYYY") <=
              moment(new Date(availableDate), "DD/MM/YYYY") ||
            moment(new Date(dateSince), "DD/MM/YYYY") >=
              moment(new Date(hisData.collected_since), "DD/MM/YYYY") ||
            moment(new Date(availableDate), "DD/MM/YYYY") <=
              moment(new Date(hisData.collected_till), "DD/MM/YYYY");
          let checkToDate =
            moment(new Date(dateSince), "DD/MM/YYYY") <
              moment(new Date(availableDate), "DD/MM/YYYY") &&
            moment(new Date(dateSince), "DD/MM/YYYY") <=
              moment(new Date(hisData.collected_till), "DD/MM/YYYY");
          let cmpDate = checkToDate && checkFrDate;
          let isHide = cmpDate
            ? moment(new Date(hisData.collected_since), "DD/MM/YYYY") <=
                moment(new Date(availableDate), "DD/MM/YYYY")
            : cmpDate;
          if (isHide) {
            newTrackedIds.push(hisData.storeid);
            newCheckedStores.push(hisData.storeid + "|1");
          }
        });
        newIsSelectedStores = newTrackedIds.length > 0;
      }
    }
    setTrackedIds(newTrackedIds);
    setCheckedStores(newCheckedStores);
    setIsSelectedStores(newIsSelectedStores);

    if (newTrackedIds.length > 0) {
      getPrice();
    }
  };
  const selectStoreNames = (event, id) => {
    getStoreCounts(0);
    setCheckedStores((prevCheckedStores) => {
        let newCheckedStores = [...prevCheckedStores];
        let index = newCheckedStores.indexOf(id + "|0");
        let index1 = trackedIds.indexOf(id);
        if (event.target.checked) {
            if (index >= 0) {
                newCheckedStores.splice(index, 1);
            }
            newCheckedStores.push(id + "|1");
            if (index1 >= 0) {
                setTrackedIds((prevTrackedIds) => {
                    let newTrackedIds = [...prevTrackedIds];
                    newTrackedIds.splice(index1, 1);
                    return newTrackedIds;
                });
            }
            setTrackedIds((prevTrackedIds) => [...prevTrackedIds, id]);
        } else {
            index = newCheckedStores.indexOf(id + "|1");
            if (index >= 0) {
                newCheckedStores.splice(index, 1);
            }
            newCheckedStores.push(id + "|0");
            if (index1 >= 0) {
                setTrackedIds((prevTrackedIds) => {
                    let newTrackedIds = [...prevTrackedIds];
                    newTrackedIds.splice(index1, 1);
                    return newTrackedIds;
                });
            }
        }
        let isSelectAll = newCheckedStores.filter(x => x.endsWith("|1")).length === storeCounts;
        setIsSelectedStores(isSelectAll);
        return newCheckedStores;
    });
    if (trackedIds.length > 0) {
        // getPrice();
    }
};
  // let userStoreId = 182660;
  function priceConversion(tot) {
    if (tot !== "") {
      let checkDigit = tot.toString().split(".");
      let ret = "";
      let i = 0;
      if (tot.toString().indexOf(".") === -1) {
        return tot;
      } else {
        if (checkDigit === "00") {
          tot = checkDigit.trim();
        } else {
          if (checkDigit.length === 2) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              if (i >= checkDigit[1].length) ret += "0";
              else ret += checkDigit[1][i];
            }
          } else if (checkDigit.length === 1) {
            ret += checkDigit[0] + ".";
            for (i = 0; i < checkDigit.length; i++) {
              ret += "0";
            }
          }
          ret = ret.toString();
          tot = ret;
        }
      }
    }
    return tot;
  }
  useEffect(() => {
    if (trackedIds.length > 0) {
      getPrice();
    }
  }, [trackedIds, actualPrice, selYrsData])
  const getPrice = () => {
    let payLoads = {
      storeIds: trackedIds.toString(),
      startDate: parseInt(selYrsData) === 1 ? null : moment(dateSince).format('YYYY-MM-DD'),
      endDate: parseInt(selYrsData) === 1 ? null : moment(availableDate).format('YYYY-MM-DD'),
      isAllYears: parseInt(selYrsData) === 1 ? true : false,
    };
    setHisDataPurchase(payLoads);
    setIsLoading(true);
    setIsFinalMsg(false);
    api.post(CONFIG.API_URL + "HistoricalReport/historicaldataprice", payLoads)
      .then((response) => {
        let tot = response.data;
        let actualPrice = parseFloat(tot.actualPrice) === 0.00 ? 0.00 : priceConversion(tot.actualPrice);
        let discountPrice = parseFloat(tot.discountPrice) === 0.00 ? 0.00 : priceConversion(tot.actualPrice === tot.discountPrice ? tot.discountPrice : tot.actualPrice - tot.discountPrice);
        let totalPrice = tot.actualPrice === tot.discountPrice ? 0.00 : parseFloat(discountPrice) === 0.00 ? actualPrice : discountPrice;
        let isInvoice = tot.isInvoice === 1 ? true : false;
        setIsLoading(false);
        setTotalPrice(totalPrice);
        setActualPrice(actualPrice);
        setDiscountPrice(discountPrice);
        setIsInvoice(isInvoice);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  console.log(actualPrice)
  //Sorting Functionalities
  const sortPopUp = (getName) => {
    let sortedData = [...historicalData];
    sortedData.sort((a, b) => {
      const textA = a.proximity;
      const textB = b.proximity;
      if (getName === "proximityAsc") {
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      } else if (getName === "proximityDesc") {
        return textA > textB ? -1 : textA < textB ? 1 : 0;
      }
      return 0;
    });
    setHistoricalData(sortedData);
    setIsSelectedStores(false)
    setSortUpdateProximity(getName === "proximityAsc");
    forceUpdate();
  };
  let marketRefInput = {
    // marketid: userStoreId,
    storeIds: trackedIds.toString(),
    startdate: parseInt(selYrsData) === 1 ? null : moment(dateSince).format('YYYY-MM-DD'),
    enddate: parseInt(selYrsData) === 1 ? null : moment(availableDate).format('YYYY-MM-DD'),
    userid: localStorage.getItem('userId'),
    isallyears: parseInt(selYrsData) === 1 ? true : false,
    reporttypeid: ReportTypeId.HistoricalReport,
    totalprice: actualPrice,
    numberofcompetitor: trackedIds ? trackedIds.length : 0,
    numberofdays: 0,
  };
  useEffect(() => {
    if (trackedIds && trackedIds.length > 0) {
      getStoreCounts(1);      
    }
  }, [storeCounts]);
  
  const handleSpiltAddress = (data, latLong) => {
    let lCityName = "";
    let lAddress = "";
    let resData = data;
    let zipCodeText = resData?.text && resData.text.replace(" ", "").length < 8 && (/[A-Z][0-9]/).test(resData.text) ? resData.text.replace(" ", "") : "";
    let localName = resData?.context.filter(val => val.id.includes("locality"))[0]?.text ?? "";

    lAddress = resData?.text.includes(resData?.address) ? resData?.text : (resData?.address ? resData.address : "") + (resData?.text && !zipCodeText ? ((resData?.address ? " " : "") + (data.id?.includes('postcode') ? '' : resData.text)) : "") + (localName ? (", " + localName) : "");
    let address = lAddress ?? "";
    lCityName = resData?.context.filter(val => val.id.includes("place"))[0]?.text ?? "";

    let cityName = lCityName ?? "";
    let stateName = resData?.context.filter(val => val.id.includes("region"))[0]?.short_code?.split("-")[1] ?? (resData?.properties?.short_code?.split("-")[1] ?? "");
    let zipCode = resData?.context.filter(val => val.id.includes("postcode"))[0]?.text.replace(" ", "") ?? (zipCodeText ? zipCodeText : "");
    if (!zipCode) zipCode = data?.id?.includes('postcode') && resData?.text ? resData?.text : "";
    let countryName = resData?.context.filter(val => val.id.includes("country"))[0]?.text;
    let ctryId = countryId.filter((fd) => (fd.name === countryName || fd.code === countryName))[0]?.id;
    if (!cityName) {
      cityName = address;
      address = "";
      if (resData?.context.length === 1) cityName = "";
    }

    let setAddressData = (address && address !== "" ? address + ", " : "") +
      (cityName && cityName !== "" ? cityName + ", " : "") + stateName + " " + (zipCode ?? "");
    setAddress(setAddressData);
    const marketSummaryData = {
      latitude: latLong && latLong[1],
      longitude: latLong && latLong[0],
      cityName: cityName,
      stateName: stateName,
      zipcode: zipCode,
      countryid: ctryId,
      storeid: null,
      address: lAddress
    };
    setMarketSummryData({
      latitude: latLong && latLong[1],
      longitude: latLong && latLong[0],
      city: cityName,
      state: stateName,
      zipcode: zipCode,
      countryid: ctryId,
      storeid: null,
      address:lAddress
    })
    getHistoricalData("", "isScroll", marketSummaryData);
  }
  const handleSearchResult = (values) => {
    if (values){
        let latLong = values?.center;
        handleSpiltAddress(values, latLong);
    } else {
        setAddress("")
    }
  }
  const handleSearchBtn = () => {
    setSubmitted(true);
    let returnedErrors = validationFunction("search-address", address);
    errorMessageFunc("search-address", returnedErrors);
    const hasErrors = Object.values(returnedErrors).some(error => error !== "");
    if (!hasErrors) {
      getHistoricalData("", "isScroll", marketSummryData);
    }
  }
  const errorMessageFunc = (errorName, errorMessage) => {
    setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage })); 
  }
  useEffect(() => {
    handlePriceValue();
  }, [])
  const handlePriceValue = () => {
    const inputData = {
        subscriptionKey: subscriptionKey.historicalReport,
        units: 0,
        noOfDays: 0
    }
    apiBIG.post("/purchase/billing/prices", inputData)
        .then((res) => {
            const lowestPriceSubscription = res.data.reduce((prev, current) => {
                return (prev.price < current.price) ? prev : current;
            });
            setHisDiscountGrid(res.data);
            setPriceValue(lowestPriceSubscription?.price)
        })
        .catch((err) => {
            console.log(err);
        });
};
  return (
    <>
    {enableLoader && <div className="loader-wrap"><div className="loading"></div> : </div>}
    <div className="market_reports_page">
      <ReportHome reportType="HistoricalData" {...{
        priceValue, handleSearchResult, handleSearch, handleSearchBtn, setSearchInput, openSearch,
        setOpenSearch, searchSuggestion, errors, props, searchInput,setSubmitted,submitted
      }} />
      <div className="data-driven">
        <Container className="text-center">
            <div className="data-driven-content historical-reports-p ">
            <h3>Tap into the most comprehensive historical market data available for self-storage</h3>
            <p>
            Our instant historical rate reports provide daily rates for specified stores, including Store Name, Address, Unit Size and Features, Walk-in Rates, Online Rates, and Promotions. Historical rate data is essential for making data-driven decisions, enabling trend analysis, competitive benchmarking, revenue optimization, and understanding market demand to strategically manage pricing or make savvy investment decisions.
            </p>
            <h5>Enter an address above to get started!</h5>
          </div>        
        </Container>
      </div>
      <div id="historical-summary-scroll" ref={hiscrollRef}>
        {showSection && (
          <section>
            <div className="your-market-fields">
              <Container fluid>
              <div className='your-market-address historical-reports-slider'>
                    <h6 className='your-label'>Your Market: </h6><span className='address-value'>{address ? address : "N/A"}</span>   
                    <br />  
                    <div className='historical-searchtype-sec'>
                    <h6 className='type-text'>Search Type: </h6>
                      <FormControl
                      className="ld_fs radius-city"
                      component="fieldset"
                      style={{marginTop: "0px" }}
                    >
                      <RadioGroup
                        row
                        className="listRadio"
                        aria-label="lm_viewType"
                        name="searchtype"
                        value={searchTypeId.toString()} 
                        onChange={handleChangeMarket}
                      >
                        {marketSearch && marketSearch.length > 0
                          ? marketSearch.map((data, index) => {
                              return (
                                <div key={index} className="radio-btns">
                                  <FormControlLabel
                                    className="radio_cls"
                                    value={data.id.toString()} // Ensure value is converted to string
                                    control={<Radio />}
                                    label={data.name}
                                  />
                                </div>
                              );
                            })
                          : ""}
                      </RadioGroup>
                    </FormControl>   
                    </div>         
                    {searchTypeId === 1 && (
                      <>
                          <h6 className='your-label'> Market Radius: </h6>
                          <span className='address-value'>{radiusValue ? radiusValue + " miles radius" : ""}</span>
                          <Slider
                              value={radiusValue}
                              onChangeCommitted={updateRadiusValue}
                              aria-labelledby="input-slider"
                              step={1}
                              marks={radiusLevel.map(val => { return { value: val, label: (val + " mi") } })}
                              min={1}
                              max={10}
                          />
                      </>
                  )}                  
                  </div>
                <Row>
                  <div className="mt-3 is-it-store histroical-rep-excel">
                    <Col md="12" className="p-v-15 border-box shadow-sm rounded padding-bottom-0">
                      <Row>
                      {showresultSection && (
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                          {resetBtn ? <button onClick={resetValues} className="historical-reports-resetbtn ms-3">Reset</button> : ""}
                        </div>
                      )} 
                      </Row>
                      <Row>
                        <Col md="10">                        
                          <Table className="table table-bordered custom-table">
                            <TableHead>
                              <TableRow className="table-header">
                                <TableCell>
                                  <div>Location Name / Address</div>{" "}
                                  <Checkbox
                                    className="track-check-gl"
                                    checked={isSelectedStores}
                                    //disabled={historicalData && (historicalData.length === 0 || historicalData === null) ? true : false}
                                    onClick={(event) => SelectAllStores(event)}
                                  />
                                  <span style={{ marginRight: "5px", verticalAlign: "middle", }}>Select/UnSelect All</span>
                                </TableCell>
                                <TableCell className={ShowProxSort === true ? "sort-active" : ""}>Proximity
                                  <TableSortLabel
                                    direction={sortUpdateProximity ? "desc" : "asc"}
                                    onClick={
                                      sortUpdateProximity === true
                                        ? () => sortPopUp("proximityDesc")
                                        : () => sortPopUp("proximityAsc")
                                    }
                                  ></TableSortLabel>
                                </TableCell>
                                <TableCell>Collecting Since</TableCell>
                                <TableCell>Latest Available</TableCell>
                                <TableCell>Total Data Available</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {historicalData &&
                              historicalData !== null &&
                              historicalData.length > 0 ? (
                                historicalData.map((hisData) => {
                                  let year =
                                    Math.floor(hisData.months / 12) !== 0
                                      ? Math.floor(hisData.months / 12) === 1
                                        ? Math.floor(hisData.months / 12) + " yr"
                                        : Math.floor(hisData.months / 12) + " yrs"
                                      : "";
                                  let month =
                                    hisData.months -
                                      Math.floor(hisData.months / 12) * 12 !==
                                    0
                                      ? hisData.months -
                                          Math.floor(hisData.months / 12) * 12 ===
                                        1
                                        ? hisData.months -
                                          Math.floor(hisData.months / 12) * 12 +
                                          " month"
                                        : hisData.months -
                                          Math.floor(hisData.months / 12) * 12 +
                                          " months"
                                      : "";
                                  let checkFrDate =
                                    moment(
                                      new Date(hisData.collected_since),
                                      "DD/MM/YYYY"
                                    ) >= moment(new Date(dateSince), "DD/MM/YYYY") ||
                                    moment(
                                      new Date(hisData.collected_till),
                                      "DD/MM/YYYY"
                                    ) <=
                                      moment(new Date(availableDate), "DD/MM/YYYY") ||
                                    moment(new Date(dateSince), "DD/MM/YYYY") >=
                                      moment(
                                        new Date(hisData.collected_since),
                                        "DD/MM/YYYY"
                                      ) ||
                                    moment(new Date(availableDate), "DD/MM/YYYY") <=
                                      moment(
                                        new Date(hisData.collected_till),
                                        "DD/MM/YYYY"
                                      );
                                  let checkToDate =
                                    moment(new Date(dateSince), "DD/MM/YYYY") <
                                      moment(new Date(availableDate), "DD/MM/YYYY") &&
                                    moment(new Date(dateSince), "DD/MM/YYYY") <=
                                      moment(
                                        new Date(hisData.collected_till),
                                        "DD/MM/YYYY"
                                      );
                                  let cmpDate = checkToDate && checkFrDate;
                                  let isHide = cmpDate
                                    ? moment(
                                        new Date(hisData.collected_since),
                                        "DD/MM/YYYY"
                                      ) <=
                                      moment(new Date(availableDate), "DD/MM/YYYY")
                                    : cmpDate;
                                  return (
                                    <TableRow
                                      key={hisData.storeid}
                                      className={
                                        selYrsData === 2
                                          ? isHide
                                            ? ""
                                            : "cell-disable"
                                          : ""
                                      }
                                    >
                                      <TableCell className="location-add-col">
                                        <Checkbox
                                          key=""
                                          className="track-check-gl"
                                          checked={
                                            hisData.storeid + "|1" ===
                                            checkedStores.find(
                                              (e) => e === hisData.storeid + "|1"
                                            )
                                          }
                                          onClick={(event) =>
                                            selectStoreNames(
                                              event,
                                              hisData.storeid
                                            )
                                          }
                                          onKeyDown={(event) => {
                                            if (event.key === " " || event.key === "Tab") {
                                              event.preventDefault();
                                            }
                                          }}
                                        />
                                        <div
                                          className="address-show"
                                          dangerouslySetInnerHTML={{
                                            __html: hisData.storename,
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell>
                                        {hisData.proximity === null
                                          ? "--"
                                          : hisData.proximity === 0
                                          ? ""
                                          : hisData.proximity + " mi"}
                                      </TableCell>
                                      <TableCell>
                                        {moment(hisData.collected_since).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {moment(hisData.collected_till).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {year} {month}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan="6"
                                    className="text-center no-data"
                                    style={{color: "red"}}
                                  >
                                    {isLoading
                                      ? "Please Wait for the data to be loaded."
                                      : historicalData === null
                                      ? ""
                                      : "There are no historical data available."}
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </Col>
                        <Col md="2" className="p-0">
                          <div className="report-right-details historical-reports">
                            <h4>
                              Report Details
                              <HtmlTooltip
                                interactive
                                arrow
                                // placement="bottom-end"
                                title={
                                  <React.Fragment>
                                    <Table className="popup-txt-table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>No. of Competitors</TableCell>
                                          <TableCell>Price</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                      {hisDiscountGrid && hisDiscountGrid.length > 0
                                        ? hisDiscountGrid
                                            .sort((a, b) => a.maxCount - b.maxCount) // Sort the array by maxCount
                                            .map((data, i) => {
                                              let price = priceConversion(data.price);
                                              return (
                                                <TableRow key={i}>
                                                  <TableCell>
                                                    {data.maxCount > 50
                                                      ? "50+ Selected Competitors"
                                                      : data.maxCount === 50
                                                      ? "Up to 50 Selected Competitors"
                                                      : data.maxCount === 20
                                                      ? "Up to 20 Selected Competitors"
                                                      : "Up to 10 Selected Competitors"}
                                                  </TableCell>
                                                  <TableCell>
                                                    <span>
                                                      {CONFIG.Currency_Symbol}
                                                      {price} per store/year
                                                    </span>
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })
                                        : ""}                                                                                                        
                                      </TableBody>
                                    </Table>
                                  </React.Fragment>
                                }
                              >
                                <p className="by-state-des calculator-icon">
                                  <span className="pop-up-link">
                                    <FontAwesomeIcon
                                      icon={faCalculator}
                                      aria-label="Help"
                                    />
                                  </span>
                                </p>
                              </HtmlTooltip>
                            </h4>
                            <p className="mb-0">Years of Data:</p>
                            <FormControl
                              className="ld_fs"
                              component="fieldset"
                              style={{ width: "100%", marginTop: "3px" }}
                            >
                              <RadioGroup
                                className="listRadio"
                                aria-label="lm_viewType"
                                name="yearsOfData"
                                value={selYrsData}
                                onChange={handleChange}
                              >
                                {yearsOfData && yearsOfData.length > 0
                                  ? yearsOfData.map((data, index) => {
                                      return (
                                        <div key={index} className="radio-btns">
                                          <FormControlLabel
                                            className="radio_cls"
                                            value={data.id}
                                            control={<Radio />}
                                            label={data.name}
                                          />
                                        </div>
                                      );
                                    })
                                  : ""}
                              </RadioGroup>
                            </FormControl>
                            {selYrsData === 2 ? (
                              <Row className="date-select-right">
                                <Col md={6} className="pr-1 from-select">
                                  <div>
                                    {" "}
                                    From
                                    <DatePicker
                                      dateFormat="dd-MMM-yyyy"
                                      selected={dateSince}
                                      onChange={handleSDateChange}
                                      disabled={selYrsData === 1 ? true : false}
                                      maxDate={moment().toDate()}
                                    />
                                  </div>
                                </Col>
                                <Col md={6} className="pl-1 to-select">
                                  <div>
                                    To
                                    <DatePicker
                                      id="availableDate"
                                      dateFormat="dd-MMM-yyyy"
                                      selected={availableDate}
                                      maxDate={moment().toDate()}
                                      onChange={handleLDateChange}
                                      disabled={selYrsData === 1 ? true : false}
                                    />
                                  </div>                                  
                                </Col>
                                {validationMessage && <div style={{ color: 'red', marginTop: '5px' }}>{validationMessage}</div>}
                              </Row>
                            ) : (
                              ""
                            )}
                            {selYrsData === 2 && showText ? (
                              <p className="sqft-disclaimer mb-1 clr-bla-opa">
                                <span style={{ fontSize: "13px" }}>*</span> Data is
                                unavailable for some stores for the selected time
                                range.
                              </p>
                            ) : (
                              ""
                            )}
                            <p className="mb-1 label-price">
                              Total Locations in report:{" "}
                              <span>
                                {trackedIds.length > 0 ? trackedIds.length : ""}
                              </span>
                            </p>
                            {trackedIds.length > 0 ? (
                              <p className="mb-1 label-price">
                                Total Price:
                                {isLoading ? (
                                  <img src={loaderDownload} className="ms-3" alt="wait" />
                                ) : (
                                  discountPrice === actualPrice ? (
                                    <>
                                      <NumberFormat
                                        className={!validationMessage ? "discount-strike" : "discount-validation"}
                                        value={actualPrice}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={CONFIG.Currency_Symbol}
                                      />
                                      <NumberFormat
                                        value="0.00"
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={CONFIG.Currency_Symbol}
                                      />
                                    </>
                                  ) : parseFloat(discountPrice) === 0.0 ? (
                                    <NumberFormat
                                      value={actualPrice}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                      prefix={CONFIG.Currency_Symbol}
                                    />
                                  ) : (
                                    <>
                                      <NumberFormat
                                        className={!validationMessage ? "discount-strike" : "discount-validation"}
                                        value={actualPrice}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={CONFIG.Currency_Symbol}
                                      />
                                      <NumberFormat
                                        value={discountPrice}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={CONFIG.Currency_Symbol}
                                      />
                                    </>
                                  )
                                )}
                              </p>
                            ) : (
                              ""
                            )}                                                       
                            {trackedIds.length > 0 && discountPrice !== actualPrice && Number(totalPrice) < CONFIG.MinimumHistoricalReportPrice && <div className="note-amount"><b>Note: </b>The minimum bill amount is {CONFIG.Currency_Symbol}{CONFIG.MinimumHistoricalReportPrice}.</div>}
                            <HistoricalPurchaseDetails
                              searchTypeId={searchTypeId}
                              validationMessage={validationMessage}
                              trackedIds={trackedIds}
                              marketRefInput={marketRefInput}
                              actualPrice = {actualPrice}
                              addressDetails = {marketSummryData }
                              radiusDetails = {radiusValue}/>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Dialog
                      className="SingleMarket landing-stepper landing-popup mvp-landing-popup pay-now-btm-fixed"
                      open={isFinalMsg}
                      aria-labelledby="form-dialog-title"
                    >
                      <DialogContent>
                        <div className="last-step thank-you-purchase">
                          <Container>
                            <Row>
                              <Col md="12">
                                <Paper
                                  className="p-3 elevation0"
                                  component="div"
                                  align="center"
                                >
                                  <Typography
                                    variant="h3"
                                    component="h3"
                                    className="pb-2 thank-you"
                                  >
                                    Thank you for requesting a historical rate report!
                                  </Typography>
                                  <Typography variant="h6" component="h6">
                                    You will receive an email confirmation shortly.
                                    Please allow up to 30 minutes for your report to
                                    be ready. When ready you will receive an email to
                                    download your report OR go to your Historical
                                    Reports dashboards to access it.
                                  </Typography>
                                  <Typography
                                    component="p"
                                    gutterBottom
                                    classname="fnt-14"
                                  >
                                    <Link
                                      to={{
                                        pathname: "/historical-reports/",
                                      }}
                                      className="all-reports sample-reports btn btn-secondary"
                                    >
                                      Got it!
                                    </Link>
                                  </Typography>
                                </Paper>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </Row>
              </Container>
            </div>
          </section>
        )}
      </div>     
    </div>
    </>
  );
};

export default HistoricalReports;
