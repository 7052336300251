import { Outlet } from "react-router";
import { Footer, Header } from "../../core";

const DefaultLayout = () =>{
    return(
        <>
        <Header/>
        <Outlet/>
        <Footer/>
        </>
    )
}

export default DefaultLayout ;