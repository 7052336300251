import React, { useEffect, useState } from 'react'
import ReportHome from '../CommonComponent/ReportHome/ReportHome'
import purchasereport from '../../assets/Images/purchase_report.png';
import { FormControl, FormControlLabel, Radio, RadioGroup, Slider} from '@mui/material';
import {  Col, Row, Container } from 'reactstrap';
import { CONFIG } from '../../utils/config';
import { api, apiBIG } from '../../services/api';
import { countryId, subscriptionKey } from '../../utils/enum';
import MarketSummary from '../MarketSummary/marketSummary';
import { validationFunction } from '../CommonFunction/validation';
import { useParams } from 'react-router';
import PurchaseDetails from './PurchaseDetails';
import { handlingCatchErorr } from '../../utils/errorHandling';

const MarketReports = (props) => {
  const [errors, setErrors] = useState({});
  const radiusLevel = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [radiusValue, setRadiusValue] = useState(5);
  const [searchInput, setSearchInput] = useState("");
  const [searchSuggestion, setSearchSuggestion] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [address, setAddress] = useState('');
  const [priceValue, setPriceValue] = useState("")
  const [snapData, setSnapData] = useState({})
  const [demoGphData, setDemoGphData] = useState({})
  const [knownDevelopment, setKnownDevelopment] = useState({})
  const [addDemoGphData, setAddDemoGphData] = useState({})
  const [enableLoader, setEnableLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [priceLoader,setPriceLoader]  = useState(false) 
  const [submitted, setSubmitted] = useState(false);
  const [marketSummryData, setMarketSummryData] = useState({
    latitude: null,
    longitude: null,
    city: null,
    state: null,
    zipcode: null,
    countryid: null,
    storeid: null,
    address :null
  });
  const [storeDetails,setStoreDetails]= useState({})
  const { Address, City, State, Zipcode,propertyid } = useParams();

  const [searchTypeId,setSearchTypeId]= useState(1)

  const marketSearchType = [
    { id: 1, name: "Radius" },
    { id: 3, name: "City" },
  ];
  const handleChangeMarketType = (e) => {
    const id = parseInt(e.target.value); // Ensure value is parsed to integer
    setSearchTypeId(id);
    if (props.from === "LSS") {
      handleMarketSummaryData("",storeDetails, "","", id)
    
    }
    else{
      handleMarketSummaryData("","","",marketSummryData , id)
    }
  };

  const updateRadiusValue = (e, value) => {
    let returnedErrors = validationFunction("search-address", address);
    errorMessageFunc("search-address", returnedErrors);

    // Check if there are any errors
    const hasErrors = Object.values(returnedErrors).some(error => error !== "");
    if (!hasErrors) {
      setRadiusValue(value);
      handleMarketSummaryData(value,storeDetails,"radius")
    }
  }

  useEffect(() => {
    if (props.from === "LSS") {
      let setAddressData = (Address && Address !== "" ? Address + ", " : "") +
      (City && City !== "" ? City + ", " : "") + State + " " + (Zipcode ?? "");
      setAddress(setAddressData);

      getStoreAddress({
        latitude: 0,
        longitude: 0,
        cityName: City,
        stateName: State,
        zipCode: Zipcode,
        address: Address,
        IsLSS : true,
        StoreId:propertyid

      })
    }
  }, [])



  useEffect(() => {
    handlePriceValue();
  }, [])

  const handlePriceValue = () => {
    setPriceLoader(true)
    const inputData = {
      subscriptionKey: subscriptionKey.marketExplorer,
      units: 0,
      noOfDays: 0
    }
    apiBIG.post("/purchase/billing/prices", inputData)
      .then((res) => {
        setPriceLoader(false)
        const lowestPriceSubscription = res.data.reduce((prev, current) => {
          return (prev.price < current.price) ? prev : current;
        });

        setPriceValue(lowestPriceSubscription?.price)
      })
      .catch((err) => {
        setPriceLoader(false)
        handlingCatchErorr(err)
      });
  };

  const handleSearch = async (e) => {
    setSubmitted(false)
    setErrors({})
    const inputValue = e.target.value;
    setSearchInput(inputValue);

    if (inputValue.trim() === '') {
      setSearchSuggestion([]);
      return;
    }
      mapSearchAddress(inputValue);
   
  }

  const mapSearchAddress = async (inputValue) => {
    const accessToken = 'pk.eyJ1IjoiY2Vuc3RyYWNrIiwiYSI6ImNrbHVweHQxNDJpdncybmtkOHRpZzZ1M2YifQ.5wzmtoTYFiS01eAy1LAI1Q';
    const country = "us,ca";
    const language = 'en';
    const limit = 10;
    const types = 'address,place,locality,postcode'; // Comma-separated list of types
    const apiUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(inputValue)}.json?country=${country}&limit=${limit}&types=${types}&language=${language}&access_token=${accessToken}`;

    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch suggestions');
      }

      const data = await response.json();
      const features = data.features;
      setSearchSuggestion(features);
    } catch (error) {
      setSearchSuggestion([])
      console.error('Error fetching suggestions:', error.message);
    }
  }

  const handleSearchResult = (values) => {
    setSnapData([])
    if (values) {
    
       let latLong = values?.center;
       handleSpiltAddress(values, latLong);
    } else {
      setAddress("")
    }


  }


  const handleSpiltAddress = (data, latLong) => {
    let lCityName = "";
    let lAddress = "";
    let resData = data;
    let zipCodeText = resData?.text && resData.text.replace(" ", "").length < 8 && (/[A-Z][0-9]/).test(resData.text) ? resData.text.replace(" ", "") : "";
    let localName = resData?.context.filter(val => val.id.includes("locality"))[0]?.text ?? "";

    lAddress = resData?.text.includes(resData?.address) ? resData?.text : (resData?.address ? resData.address : "") + (resData?.text && !zipCodeText ? ((resData?.address ? " " : "") + (data.id?.includes('postcode') ? '' : resData.text)) : "") + (localName ? (", " + localName) : "");
    let address = lAddress ?? "";
    lCityName = resData?.context.filter(val => val.id.includes("place"))[0]?.text ?? "";

    let cityName = lCityName ?? "";
    let stateName = resData?.context.filter(val => val.id.includes("region"))[0]?.short_code?.split("-")[1] ?? (resData?.properties?.short_code?.split("-")[1] ?? "");
    let zipCode = resData?.context.filter(val => val.id.includes("postcode"))[0]?.text.replace(" ", "") ?? (zipCodeText ? zipCodeText : "");
    if (!zipCode) zipCode = data?.id?.includes('postcode') && resData?.text ? resData?.text : "";
    let countryName = resData?.context.filter(val => val.id.includes("country"))[0]?.text;
    let ctryId = countryId.filter((fd) => (fd.name === countryName || fd.code === countryName))[0]?.id;
    if (!cityName) {
      cityName = address;
      address = "";
      if (resData?.context.length === 1) cityName = "";
    }
    let setAddressData = (address && address !== "" ? address + ", " : "") +
      (cityName && cityName !== "" ? cityName + ", " : "") + stateName + " " + (zipCode ?? "");
    setAddress(setAddressData);
    let addressDetails = (address && address !== "" ? address  : "") ;
    setMarketSummryData({
      latitude: latLong && latLong[1],
      longitude: latLong && latLong[0],
      city: cityName,
      state: stateName,
      zipcode: zipCode,
      countryid: ctryId,
      storeid: null,
      address:addressDetails
    })
    const datas = {
      latitude: latLong && latLong[1],
      longitude: latLong && latLong[0],
      city: cityName,
      state: stateName,
      zipcode: zipCode,
      countryid: ctryId,
      storeid: null
    }
    getStoreAddress({
      latitude: latLong && latLong[1],
      longitude: latLong && latLong[0],
      cityName: cityName,
      stateName: stateName,
      zipCode: zipCode,
      countryId: ctryId,
      address: addressDetails,
      dataValue:datas
    });
    
  }

  const handleMarketSummaryData = (value, LSSinput, scroll,dataValue,id) => {
   const summaryInput = scroll === "radius" ? marketSummryData : dataValue;
   const ids = id ?id : searchTypeId;
    setLoader(true)
    const sectionId = "market-summary-scroll";
    const latitude = summaryInput?.latitude;
    const longitude = summaryInput?.longitude;
    const cityName = summaryInput?.city;
    const stateName = summaryInput?.state;
    const ctryId = summaryInput?.countryid;
    if (props.from === "LSS" ) {
      var inputs = {
        "latitude": LSSinput?.latitude,
        "longitude": LSSinput?.longitude,
        ...(ids === 1 ? {"radius": value ? value : radiusValue} : ""),
        "city": LSSinput?.city,
        "state": LSSinput?.state,
        "countryId": LSSinput?.countryid,
        "storeId":LSSinput?.storeid,
        "resultSetNumber": 1,
        "marketId": null,
        "IsMarketExplorer": true,
        "polygonArea": "",
        "searchTypeId":ids,
      }
    } else {
      var inputs = {
        "latitude": latitude,
        "longitude": longitude,
        "polygonArea": "",
        ...(ids === 1 ? {"radius": value ? value : radiusValue} : ""),
        "city": cityName,
        "state": stateName,
        "countryId": ctryId,
        "resultSetNumber": 1,
        "marketId": null,
        "IsMarketExplorer": true,
        "searchTypeId":ids

      }
    }

    api.post("/MarketReport/summary", inputs)
      .then((res) => {
        setLoader(false)
        let storeInfo = res.data.storeInfo ?? {};
        let demographicInfo = res.data.demographicInfo ?? {};
        let knowndevelopmentinfo = res.data.developmentInfo ?? {};
        let additionalDemoGraphicInfo = res.data.additionalDemoGraphicInfo ?? {};

        setSnapData(storeInfo)
        setDemoGphData(demographicInfo)
        setKnownDevelopment(knowndevelopmentinfo)
        setAddDemoGphData(additionalDemoGraphicInfo)
        
        if (scroll === "isScroll") {
          const section = document.getElementById(sectionId);

    if (section) {
        // Calculate the position of the section relative to the document
        const sectionPosition = section.getBoundingClientRect().top + window.scrollY;

        // Scroll to the position of the section
        window.scrollTo({
            top: sectionPosition,
            behavior: 'smooth'
        });
    }
        }
      })
      .catch((err) => {
        setLoader(false)
        handlingCatchErorr(err)
      })
  }

  const handleSearchBtn = () => {
    setSubmitted(true);
    let returnedErrors = validationFunction("search-address", address);
    errorMessageFunc("search-address", returnedErrors);

    // Check if there are any errors
    const hasErrors = Object.values(returnedErrors).some(error => error !== "");

    // If there are no errors, call the API
    if (!hasErrors) {
      
      handleMarketSummaryData("", " ", "isScroll",marketSummryData); // 
    }
  }


  const errorMessageFunc = (errorName, errorMessage) => {
    setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));

  }

  const getStoreAddress = ({ latitude, longitude, cityName, stateName, zipCode, address , IsLSS,StoreId,dataValue}) => {
    setEnableLoader(true)
    const input = {
      "address": address,
      "city": cityName,
      "state": stateName,
      "zipcode": zipCode,
      "latitude": latitude,
      "longitude": longitude,
      "countryId": null,
      "isLSS": IsLSS,
      "storeId" :StoreId,
      "searchTypeId":searchTypeId
    }
    api.post("/Store/storeaddress", input)
      .then((res) => {
        if (props.from === "LSS") {
          const storeDetails = res.data?.storeProfile;
          setStoreDetails(res.data .storeProfile)
          handleMarketSummaryData(radiusValue, storeDetails, "isScroll")
        }else{
          handleMarketSummaryData("", " ", "isScroll",dataValue); // 
        }
        setEnableLoader(false)
      })
      .catch((err) => {
        setEnableLoader(false)
        handlingCatchErorr(err)
      })
  }
  return (
    <>
    

      {(enableLoader || loader  || priceLoader)  && <div className="loader-wrap"><div className="loading"></div> : </div>}
      <div className='market_reports_page'>
        <ReportHome {...{
          priceValue, handleSearchResult, handleSearch, handleSearchBtn, setSearchInput, openSearch,
          setOpenSearch, searchSuggestion, errors, props,searchInput,setSubmitted,submitted
        }} />
        <div id="market-summary-scroll">
        <div className='data-driven'>
              <Container>
                <div className='data-driven-content'>
                  <h3>Instant, data-driven, informed decisions.</h3>
                  <p>Unlock targeted insights with our Market Reports—purchase individually without a paid subscription. Each report offers metrics for a 1-10mi radius, plus 30-day access to <a href={CONFIG.Explorer_url} target='_blank'>Explorer's</a> interactive data dashboard for in-depth, granular self-storage data.</p>
                  <h5>Starting at only {CONFIG.Currency_Symbol}{priceValue}/report</h5>
                </div>
              </Container>
            </div>
        {Object.keys(snapData).length>0 &&  address ?
          <>
            
            <div className='your-market-fields'>
              <Container>
                <Row>
                  <Col md={5}>
                  <div className='your-market-address'>
                      <h6 className='your-label'>Your Market: </h6><span className='address-value'>{address ? address : "N/A"}</span> <br/>
                      <div className='searchtype-sec'>
                      <h6 className='type-text'>Search Type: </h6>
                      <FormControl
                      className="ld_fs radius-city"
                      component="fieldset"
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                          <RadioGroup
                           row
                            className="listRadio"
                            aria-label="lm_viewType"
                            name="searchtype"
                            value={searchTypeId.toString()} // Ensure value is converted to string
                            onChange={handleChangeMarketType}
                          >
                            {marketSearchType.map((data) => (
                              <div key={data.id} className="radio-btns">
                                <FormControlLabel
                                  className="radio_cls"
                                  value={data.id.toString()} // Ensure value is converted to string
                                  control={<Radio />}
                                  label={data.name}
                                />
                              </div>
                            ))}
                          </RadioGroup>
                    </FormControl> 
                    </div>
                    {searchTypeId === 1 ? 
                    <>
                      <h6 className='your-label'>
                        Market Radius: </h6><span className='address-value'>{radiusValue ? radiusValue + " " +"miles radius" : ""} </span> 
                      <Slider

                        value={radiusValue}
                        onChangeCommitted={updateRadiusValue}
                        aria-labelledby="input-slider"
                        step={1}
                        marks={radiusLevel.map(val => { return { value: val, label: (val + " mi") } })}
                        min={1}
                        max={10}
                      />
                      </>
                      : "" }
                    </div>
                    <MarketSummary {...{ snapData, knownDevelopment, demoGphData, address, addDemoGphData, enableLoader,radiusValue,storeDetails,marketSummryData,props,searchTypeId }} />

                  </Col>
                  <Col md={7}>
                    <img src={purchasereport} className='price-banner' />
                    <h6 className='purchase-price'>only {CONFIG.Currency_Symbol}{priceValue}<br />per report</h6>
                    <PurchaseDetails 
                    addressDetails = {props.from === "LSS" ? storeDetails : marketSummryData }
                    radiusDetails = {radiusValue}
                    buttonName = "Purchase Report"
                    isDisable = {snapData?.storecount === 0 && "disable"}
                    searchId = {searchTypeId}/>
                    
                  </Col>
                </Row>
              </Container>
            </div>
          </>
          : ""
        }
        </div>
      </div>
    </>
  )
}

export default MarketReports