import React, { useEffect, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { handlingCatchErorr } from '../../utils/errorHandling';
import { useParams,useLocation } from 'react-router';
import { api } from '../../services/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import loaderDownload from "../../assets/Images/loader-download.gif";

const HistoricalReportsDownload = () => {
    const {referenceId } = useParams();
    const [loader, setLoader] = useState(false);
    const [historicalReportId, setIshistoricalReportId] = useState(false);
    const [OrderLoader,setOrderLoader] = useState(false);
    const [ordersData, setOrdersData] = useState([]);
    const [linkLoader, setLinkLoader] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const source = queryParams.get('source');
    const url =window.location.href ;
    const secureId = url.split("sec_id=")[1]
    const [searchTypeId,setSearchTypeId] = useState("")
    useEffect(() => {
        handleHistoricalOrder()
        handleHistoricalValidate()            
    }, []);
    //Historical Reports
    const handleSubmitRequest = () =>{
        setOrderLoader(true)
        const request = ({
            "secureId" : secureId.toString() ,
            "referenceId": referenceId.toString()
        })
        
        api.post("/HistoricalReport/submitrequest",request)
        .then((res) => {
            if(res.data.message === "success"){
                setOrderLoader(false)
                const historicalReportsId = res.data.historicalReportid;
                handleTriggerUpload(historicalReportsId);
            }
            
        })
        .catch((err) => {
            handlingCatchErorr(err)
            setOrderLoader(false)            
        }) 
    }
    const handleTriggerUpload = (historicalReportsId) =>{
        setOrderLoader(true)
        const request = ({
            "secureid" : secureId.toString() ,
            "referenceid": referenceId.toString(),
            "historicalReportId": historicalReportsId
        })
        
        api.post("/HistoricalReport/triggerupload",request)
        .then((res) => {
            if(res.data === "success"){
                setOrderLoader(false)
            }
            
        })
        .catch((err) => {
            handlingCatchErorr(err)
            setOrderLoader(false)
        }) 
    }
    const handleHistoricalOrder = () => {
        setOrderLoader(true);    
        const fetchOrders = () => {
            const inputs = {
                "secureid": secureId.toString(),
                "referenceid": referenceId.toString()
            };    
            api.post("/HistoricalReport/orders", inputs)
                .then((res) => {
                    const orders = res.data;
                    const searchType = Object.entries(orders).length > 0 && orders.order.searchTypeId;
                    setSearchTypeId(searchType)
                    setOrdersData(orders);    
                    // const downloadStatus = res.data.download.presignedUrl;
                    const downloadStatus = orders.download.downloadStatusId;
                    const presignedUrl = orders?.download?.presignedUrl;                    
                    if (Object.keys(orders).length > 0 && (!orders.download.presignedUrl)) {
                    setLinkLoader(true);
                    if (downloadStatus !== 6 || !presignedUrl) {
                        setTimeout(handleHistoricalOrder, 30000);
                    } else {
                        setLinkLoader(false);
                    }
                    }
                    else {
                        setLinkLoader(false);
                        setOrderLoader(false);
                    }
                })
                .catch((err) => {
                    handlingCatchErorr(err);
                    setLinkLoader(false);
                    setOrderLoader(false);
                });
        };    
        fetchOrders();
    };    
    
    const handleHistoricalValidate = ()=>{
        setLoader(true)
        const inputs = ({
            "secureid" : secureId.toString() ,
            "referenceid": referenceId.toString()
        })
        api.post("/HistoricalReport/validate", inputs)
        .then((res) => {
            setLoader(false)
            if(res.data === "success"){
                handleSubmitRequest()
            }            
        })
        .catch((err) => {
            handlingCatchErorr(err)
            setLoader(false)
        })  
    }
    //Historical Reports
    return (
        <>
        {loader || OrderLoader  && <div className="loader-wrap"><div className="loading"></div> : </div>}

        <div className="last-step">
            {/* <div className="loader-wrap"><div className="loading loading-center"></div></div>  */}
            <Container>
                <Row>
                    <Col md="12">
                        <div className="sec-msg">
                            <Paper className="p-3 elevation0" component="div" align="center" >
                                <Typography variant="h3" component="h3" className="pb-2 thank-you" >
                                    Thank you for your purchase!
                                </Typography>
                                <Typography variant="h6" component="h6">
                                    You will get an order confirmation email shortly.
                                </Typography>
                                <Typography variant="body2" className="fin-txt">
                                    <p style={{ marginBottom: '5px' }}>Historical Reports take several minutes to generate. When the report is ready, you can click on view to download the report.</p>
                                    <p>If you need to close this window, you can re-open it by clicking the link in the email confirmation sent to you.</p>
                                </Typography>
                                {Object.entries(ordersData).length > 0 && !ordersData.activationLink ? ""
                                    : <Typography variant="body2" className="fin-txt">
                                        <p style={{ marginBottom: '5px' }}>To access your account, please click here to set up <Link to={ordersData.activationLink} target="_blank">
                                            {ordersData.activationLink}</Link></p>
                                    </Typography>
                                }
                                <div className="promoCode_btn pt-2 pb-0">
                                    {/* <Table className="table table-bordered custom-table payment-table historical-reports-table" style={{ marginBottom: '10px' }}> */}
                                    <Table className={searchTypeId === 3 ? "table table-bordered custom-table payment-table historical-reports-table-citystate" : `table table-bordered custom-table payment-table ${ordersData.order?.isallyears ? "historical-reports-table-nodate" : "historical-reports-table"}`} style={{ marginBottom: '10px' }}>
                                        <TableHead>
                                            <TableRow className="table-header">
                                                {searchTypeId === 1 ? <TableCell>Address</TableCell> : ""}
                                                <TableCell>City</TableCell>
                                                <TableCell>State</TableCell>
                                                {searchTypeId === 1 ?<TableCell>Zipcode</TableCell> :""}
                                                <TableCell>No. Of Competitors</TableCell>                                                
                                                {searchTypeId === 1 ?<TableCell>Radius</TableCell> :""}
                                                {ordersData.order?.isallyears ? null : (
                                                    <>
                                                        <TableCell>Start Date</TableCell>
                                                        <TableCell>End Date</TableCell>
                                                    </>
                                                )}
                                                <TableCell>Download</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {Object.keys(ordersData).length > 0 ?                                        
                                        <TableRow >
                                                {searchTypeId === 1 ?<TableCell className='historical-reports-table-address'>{ordersData.order.address ? ordersData.order.address : "-"}</TableCell> : ""}
                                                <TableCell>{ordersData.order.city ? ordersData.order.city : "-"}</TableCell>
                                                <TableCell>{ordersData.order.state ? ordersData.order.state : "-"}</TableCell>
                                                {searchTypeId === 1 ? <TableCell>{ordersData.order.zipCode ? ordersData.order.zipCode : "-"}</TableCell> : ""}
                                                <TableCell>{ordersData.order.numberofcompetitors ? ordersData.order.numberofcompetitors : "-"}</TableCell>
                                                {searchTypeId === 1 ? <TableCell>{ordersData.order.radius} Miles Radius</TableCell> : ""}
                                                {ordersData.order.isallyears ? null : (
                                                    <>
                                                        <TableCell>{moment(ordersData.order.startdate).format("DD/MMM/YYYY")}</TableCell>
                                                        <TableCell>{moment(ordersData.order.enddate).format("DD/MMM/YYYY")}</TableCell>
                                                    </>
                                                )}
                                               <TableCell>  {linkLoader ? <img src={loaderDownload} alt="wait" /> : (ordersData.download.presignedUrl ? <Link to={ordersData.download.presignedUrl} target='_blank' className='downloadlink-view'>View</Link> : "")}</TableCell>
                                            </TableRow> : <TableRow><TableCell colSpan={9} className="no-data cart-detailsnotavailable" >{'Details not available'}</TableCell>
                                        </TableRow>
                                    }
                                    </TableBody>
                                    </Table>
                                </div>
                            </Paper>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );
}
export default HistoricalReportsDownload;