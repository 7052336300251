import { Typography, Button, ModalClose, } from '@mui/joy';
import { useEffect, useRef, useState } from 'react';
import { Grid, TextField, Box, Modal } from '@mui/material';
import { api, apiBIG } from '../../services/api';
import { ReportTypeId, lookupKey, units } from "../../utils/enum";
import { validationFunction } from '../CommonFunction/validation';
import { handlingCatchErorr } from '../../utils/errorHandling';
import { CONFIG } from '../../utils/config';

let textFieldVariables = {

    "Account Details": {
        "First Name": "fName", "Last Name": "lName", "Company": "company", "Phone": "phone", "Email Address": "email"
    }
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
let widthValues = { "email": 12, "fName": 6, "lName": 6, "company": 12, "phone": 12, }
const HistoricalPurchaseDetails = ({ addressDetails, radiusDetails, actualPrice, marketRefInput, trackedIds, validationMessage, searchTypeId }) => {
    const isFirstRender = useRef(true);
    let initialFormData = { fName: '', lName: '', company: '', phone: '', email: '' };
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({ ...initialFormData });
    const [isNextStep, setIsNextStep] = useState(false);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [isLoader, setIsLoader] = useState(false);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return
        }
        if (isNextStep) handleAccountPostSubmit();
    }, [errors])

    const handleChange = (e) => {
        if (isNextStep) setIsNextStep(!isNextStep);
        const { name } = e.target;
        const value = e.target.value;
        let returnedInfo = validationFunction(name, value);
        if (name === "phone") setFormData((prevData) => ({ ...prevData, [name]: returnedInfo?.phoneData }));
        else setFormData((prevData) => ({ ...prevData, [name]: value }));
        errorMessageFunc(name, returnedInfo);
    };
    const errorMessageFunc = (errorName, errorMessage) => {
        if (errorName === "phone") setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage.errMsg }));
        else setErrors((prevData) => ({ ...prevData, [errorName]: errorMessage }));
    }
    const finalPrice = actualPrice < CONFIG.MinimumHistoricalReportPrice ? CONFIG.MinimumHistoricalReportPrice : actualPrice;
    const handleRefereneApi = () => {        
        const addressInfo = {
            "address": addressDetails?.address,
            "city": addressDetails?.city,
            "state": addressDetails?.state,
            "zipcode": addressDetails?.zipcode,
            "countryid": addressDetails?.countryid,
            "latitude": addressDetails?.latitude,
            "longitude": addressDetails?.longitude,
            "isallyears": marketRefInput?.isallyears,
            "startdate": marketRefInput?.startdate,
            "enddate": marketRefInput?.enddate,
            "totalprice": finalPrice,
            "storeIds": marketRefInput?.storeIds,
            "reporttypeid": ReportTypeId?.HistoricalReport,
            "numberofcompetitor": marketRefInput?.numberofcompetitor,
            "searchtypeid": searchTypeId,
            "radius": radiusDetails,
        }
        api.post("/HistoricalReport/reference", addressInfo)
            .then((res) => {
                setIsLoader(false)
                handlePurchaseApi(res.data)
               
            })
            .catch((err) => {
                setIsLoader(false)
                handlingCatchErorr(err)
            });
    }

    const handlePurchaseApi = (refId) => {
        setLoader(true)
        const userDetails = formData;
        const userpurchaseInfo = {
            "login": userDetails.email,
            "firstName": userDetails.fName,
            "lastName": userDetails.lName,
            "company": userDetails.company,
            "phone": userDetails.phone,
            "LookupKey": lookupKey.historicalReport,
            "Units": units.marketExplorer,
            "Credits": 0,
            "ReferenceId":refId.toString(),
            "ReferenceUrl": `${CONFIG.Application_URL}/historical-reports/download/${refId}?${"sec_id="}`,
            "price": finalPrice,
            "ProductUrl": CONFIG.Product_historical_url,
            "SecureId": ""            
        }
        apiBIG.post("/purchase", userpurchaseInfo)
            .then((res) => {
                const url = res.data.url
                setLoader(false)
                if (url) {
                    setLoader(true)
                    window.location.replace(url);

                }
            })
            .catch((err) => {
                setLoader(false)
                handlingCatchErorr(err)
            });
    }



const handleAccountPostSubmit = () => {
   
    let areThereErrorMsgOrNot = Object.entries(errors).length !== 0 ? Object.entries(errors).every(([key, val]) => val === "") ? true : false : true;
    if (areThereErrorMsgOrNot) {
        setIsLoader(true)
        handleRefereneApi();
    }
    }

    const handleAccountPreSubmit = () => {
        setIsNextStep(true);
        Object.entries(formData).map(([key, val]) => {
            let returnedErrors = validationFunction(key, val, formData)
            errorMessageFunc(key, returnedErrors);
            return '';
        });
    }
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setErrors({})
        setIsNextStep(false);
        setFormData(initialFormData);
    }
    return (
        <>
        {( isLoader) && (
            <div className="loader-wrap">
              <div className="loading"></div>
            </div>
          )}          
            <Button onClick={handleOpen} disabled={trackedIds.length === 0 || actualPrice === 0 || validationMessage}  className='purchase-report'>Purchase Report</Button>
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                
            >
                <Box sx={style} className='purchasereport-model-popup'>
           
                <ModalClose variant="plain" sx={{ m: 1 }}  onClick={handleClose}/>
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Purchase Reports
                    </Typography>
                    {Object.entries(textFieldVariables).map(([sectionTitle, sectionFields]) => (
                        <div key={sectionTitle}>                            
                            <form className='custom-form  popupform'>
                                <Grid container spacing={3}>
                                    {Object.entries(sectionFields).map(([key, val]) => (
                                        <Grid key={key} item xs={widthValues[val]}>
                                            <TextField
                                                name={val}
                                                label={key}
                                                type={val === "email" ? "email" : "text"}
                                                value={formData && formData[val]}
                                                onChange={handleChange}
                                                fullWidth
                                                error={val !== "company" ? !!errors[val] : false}
                                                helperText={val !== "company" ? errors[val] : false}
                                                required={val !== "company" ? true : false}
                                                className='custom-fields'
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </form>
                        </div>
                    ))}
                    <Grid item xs={12} className='button-alignment popup-purchasereport'>
                        <Button variant="contained" type="button" onClick={handleClose} className='success-btn'>Cancel</Button>
                        <Button variant="contained" type="button" onClick={handleAccountPreSubmit} className='success-btn-purchase'>Purchase</Button>
                    </Grid>
              
                </Box>
            </Modal>
        </>
    );

}

export default HistoricalPurchaseDetails;