import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Country_Id, commonYears } from '../../utils/enum';
import loaderDownload from "../../assets/Images/loader-download.gif";
import NumericFormat from 'react-number-format';
import { CONFIG } from '../../utils/config';
import PurchaseDetails from '../MarketReports/PurchaseDetails';
import { Tooltip, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle} from '@fortawesome/free-solid-svg-icons';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const InfoPopup = withStyles(() => ({
    tooltip: {
        color: "white",
        letterSpacing: "1px",
        backgroundColor: "#6c757d",
        opacity: 1,
        fontSize: "11px",
        fontWeight: 400,
    },
}))(Tooltip);

const renderNumberData = (value) => {
    return (
      <NumericFormat
        value={value}
        displayType={'text'}
        thousandSeparator={true}
      />
    );
  };

const MarketSummary = ({ snapData, knownDevelopment, demoGphData, address, addDemoGphData, enableLoader,radiusValue,storeDetails,marketSummryData,props,searchTypeId }) => {
    const loaderImage = <img src={loaderDownload} alt="wait" />;
    const BuyReport = <div className='buyreport'><PurchaseDetails  
    addressDetails = {props?.from === "LSS" ? storeDetails : marketSummryData }
    radiusDetails = {radiusValue} 
    names="link"
    buttonName = "Buy Report"
    isDisable = {snapData?.storecount === 0 && "summary-disable"}
    searchId = {searchTypeId}/>
    </div>
    return (
        <Card className='marketsummary'>
            <CardContent>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                    Market Summary
                </Typography>
                <TableContainer component={Paper} className='marketreport-table'>
                    <Table  >
                        <TableBody >
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row" >
                                    Net Rentable Sq Ft

                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" >
                                    {!address ? "N/A" : enableLoader ? loaderImage : BuyReport}

                                </StyledTableCell>
                            </StyledTableRow>
                            {Country_Id.CA !== marketSummryData?.countryid ?
                                <>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" className='table-known-heading'>
                                            Sq Ft per Capita
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row"></StyledTableCell>
                                    </StyledTableRow>

                                    <StyledTableRow className='store-type-points'>
                                        <StyledTableCell component="th" scope="row" >
                                            {commonYears.FirstYear} Sq Ft per Capita
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.sqftcapita === 0 ? "N/A" : demoGphData.sqftcapita}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow className='store-type-points'>
                                        <StyledTableCell component="th" scope="row">
                                            {commonYears.SecondYear} Sq Ft per Capita
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.sqftcapita1yrforecast || !demoGphData.sqftcapita1yrforecast
                                                ? BuyReport
                                                : demoGphData.sqftcapita1yrforecast}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow className='store-type-points'>
                                        <StyledTableCell component="th" scope="row">
                                            {commonYears.ThridYear} Sq Ft per Capita
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.sqftcapita3yrforecast || !demoGphData.sqftcapita3yrforecast
                                                ? BuyReport
                                                : demoGphData.sqftcapita3yrforecast}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </>
                                : <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        Sq Ft per Capita
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.sqftcapita === 0 ? "N/A" : demoGphData.sqftcapita}
                                    </StyledTableCell>
                                </StyledTableRow>
                            }
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Sq Ft per Household
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.sqfthousehold || !demoGphData.sqfthousehold
                                        ? BuyReport
                                        : demoGphData.sqfthousehold}
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Total Stores
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : snapData.storecount || snapData.storecount === 0 ?
                                        renderNumberData(snapData.storecount)

                                        : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            {Country_Id.CA !== marketSummryData?.countryid ?
                                <StyledTableRow className='store-type-points'>
                                    <StyledTableCell component="th" scope="row">
                                        REITS
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {!address ? "N/A" : enableLoader ? loaderImage :
                                            snapData.reitcount === 0 || !snapData.reitcount ? BuyReport : snapData.reitcount ?
                                                renderNumberData(snapData.reitcount)

                                                : null}
                                    </StyledTableCell>
                                </StyledTableRow>
                                : null}
                            <StyledTableRow className='store-type-points'>
                                <StyledTableCell component="th" scope="row">
                                    Mid Ops
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage :
                                        snapData.midopscount === 0 || !snapData.midopscount
                                            ? BuyReport : snapData.midopscount ?
                                                renderNumberData(snapData.midopscount)
                                                : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow className='store-type-points'>
                                <StyledTableCell component="th" scope="row">
                                    Small Ops
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage :
                                        snapData.smallopscount === 0 || !snapData.smallopscount
                                            ? BuyReport : snapData.smallopscount ?
                                                renderNumberData(snapData.smallopscount)
                                                : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    New Developments
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage :BuyReport}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Sq Ft of Developments
                                    <InfoPopup
                                        interactive
                                        arrow placement='right'
                                        title="Includes published square footage for projects under development and StorTrack's estimates for projects with no available square footage information."
                                    >
                                        <span className='summary-kd-info'>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </span>
                                    </InfoPopup>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : knownDevelopment.totalrentablesqft === 0 ? BuyReport :
                                        knownDevelopment.totalrentablesqft ?
                                            renderNumberData(knownDevelopment.totalrentablesqft)
                                            : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            {/* /* jhvhvh */}
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Stores opened within the last year
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : snapData.storeslast12months === 0 || !snapData.storeslast12months
                                        ? BuyReport : snapData.storeslast12months ?
                                            renderNumberData(snapData.storeslast12months)
                                            : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            {Country_Id.CA !== marketSummryData?.countryid ?
                                <>
                                    <StyledTableRow >

                                        <StyledTableCell component="th" scope="row" className='table-known-heading'>
                                            Demographics
                                            <InfoPopup
                                                interactive
                                                arrow placement='right'
                                                title={marketSummryData?.countryid === "1" ?
                                                    <i>Demographics data based on Statistics Canada's
                                                        {commonYears.Canada} census data.</i> :
                                                    <i>Demographic data based on the U.S. Census Bureau's
                                                        {commonYears.FirstYear} American Community Survey.</i>}>
                                                <span className='summary-kd-info'>
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </span>
                                            </InfoPopup>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row"></StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow className='store-type-points'>
                                        <StyledTableCell component="th" scope="row">
                                            {commonYears.FirstYear} Population
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {!address ? "N/A" : enableLoader ? loaderImage : BuyReport}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow className='store-type-points'>
                                        <StyledTableCell component="th" scope="row">
                                            {commonYears.SecondYear} Population
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {!address ? "N/A" : enableLoader ? loaderImage : !demoGphData.population1yrforecast ||
                                                demoGphData.population1yrforecast === 0 ? BuyReport :
                                                demoGphData.population1yrforecast ?
                                                    renderNumberData(demoGphData.population1YrPercentageChange) : null}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow className='store-type-points'>
                                        <StyledTableCell component="th" scope="row">
                                            {commonYears.ThridYear} Population
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.population3yrforecast === 0 || !demoGphData.population3yrforecast
                                                ? BuyReport
                                                : demoGphData.population3yrforecast ?
                                                    renderNumberData(demoGphData.population3YrPercentageChange)
                                                    : null}</StyledTableCell>
                                    </StyledTableRow>
                                </>
                                :
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">Population
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {!address ? "N/A" : enableLoader ? loaderImage : BuyReport}</StyledTableCell>
                                </StyledTableRow>
                            }
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Households
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.noofhouseholds === 0 || !demoGphData.noofhouseholds ? BuyReport :
                                        demoGphData.noofhouseholds ?
                                            renderNumberData(demoGphData.noofhouseholds)
                                            : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Rental Households
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : demoGphData.hhrental === 0 || !demoGphData.hhrental ? BuyReport :
                                        demoGphData.hhrental ?
                                            renderNumberData(demoGphData.hhrental)
                                            : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Rental Households Percentage
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : !demoGphData.percentageofhhrental ? BuyReport :
                                        demoGphData.percentageofHHRental}

                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Median Household Income
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : !demoGphData.averagehouseholdincome ? BuyReport :
                                        demoGphData.averagehouseholdincome ? <>{CONFIG.Currency_Symbol}
                                            {renderNumberData(demoGphData.averagehouseholdincome)}
                                        </> : null}
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                        <StyledTableCell component="th" scope="row" className='table-known-heading'>
                                        Average Rate Per Square Feet
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row"></StyledTableCell>
                                    </StyledTableRow>
                            <StyledTableRow className='store-type-points'>
                                <StyledTableCell component="th" scope="row">
                                    All Units without Parking
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : !addDemoGphData.averageratespsfwop ? BuyReport :
                                        addDemoGphData.averageratespsfwop ?
                                            CONFIG.Currency_Symbol + addDemoGphData.averageratespsfwop : null}
                                </StyledTableCell>

                            </StyledTableRow>
                            <StyledTableRow className='store-type-points'>
                                <StyledTableCell component="th" scope="row">
                                    All Units with Parking
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : !addDemoGphData.averageratespsf ? BuyReport :
                                        addDemoGphData.averageratespsf ?
                                            CONFIG.Currency_Symbol + addDemoGphData.averageratespsf : null}
                                </StyledTableCell>

                            </StyledTableRow>
                            <StyledTableRow className='store-type-points'>
                                <StyledTableCell component="th" scope="row">
                                    Regular Units
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : !addDemoGphData.averageratespsfsuo ? BuyReport :
                                        addDemoGphData.averageratespsfsuo ?
                                            CONFIG.Currency_Symbol + addDemoGphData.averageratespsfsuo : null}
                                </StyledTableCell>

                            </StyledTableRow>
                            <StyledTableRow className='store-type-points'>
                                <StyledTableCell component="th" scope="row">
                                    Climate Controlled Units
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : !addDemoGphData.averageratespsfcc ? BuyReport :
                                        addDemoGphData.averageratespsfcc ?
                                            CONFIG.Currency_Symbol + addDemoGphData.averageratespsfcc : null}
                                </StyledTableCell>

                            </StyledTableRow>
                            <StyledTableRow className='store-type-points'>
                                <StyledTableCell component="th" scope="row">
                                    Only Parking
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : !addDemoGphData.averageRatesPSFOP ? BuyReport :
                                        addDemoGphData.averageRatesPSFOP ?
                                            CONFIG.Currency_Symbol + addDemoGphData.averageRatesPSFOP : null}
                                </StyledTableCell>

                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Rate Trend (12 months)
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage : addDemoGphData.ratetrend ? addDemoGphData.ratetrend : !addDemoGphData.ratetrend ? "N/A" : loaderImage}
                                </StyledTableCell>

                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    Units Not Advertised
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {!address ? "N/A" : enableLoader ? loaderImage :BuyReport
                                    }
                                </StyledTableCell>
                            </StyledTableRow>

                        </TableBody>
                    </Table>
                </TableContainer>

            </CardContent>

        </Card>
    );
}

export default MarketSummary;

