import React from 'react'
import { Typography, Button, ModalClose, } from '@mui/joy';
import { useState } from 'react';
import {  Box, Modal } from '@mui/material';

const HistoricalSampleReports = ({reports }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
  return (
    <>
        <Button onClick={handleOpen} className='red-btn historical-reports-viewbtn'> View Sample Report </Button>
        <Modal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            
        >
            <Box sx={style} className='purchasereport-model-popup viewsample-report '>        
            <ModalClose variant="plain" sx={{ m: 1 }}  onClick={handleClose}/>
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                >
                    View Sample Report
                </Typography>
                <div  className='historical-reports-viewsample-report'>
                    <img src={reports} alt='Historical Reports' />
                </div>
                {/* <Grid item xs={12} className='button-alignment popup-purchasereport'>
                    <Button variant="contained" type="button" onClick={handleClose} className='success-btn'>Cancel</Button>
                </Grid> */}
            
            </Box>
        </Modal>
    </>
  )
}

export default HistoricalSampleReports