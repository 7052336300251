import React from 'react';
import {  TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import gpin from '../../../assets/Images/gmap-pin.png';
import currencyicon from '../../../assets/Images/currency_icon.png';
import populationicon from '../../../assets/Images/population_icon.png';
import graphicon from '../../../assets/Images/graph_icon.png';
import ownershipicon from '../../../assets/Images/ownership_icon.png';
import canadaicon from '../../../assets/Images/canada_circle.png';
import usicon from '../../../assets/Images/us_circle.png';
import { Button, Col, Row } from 'reactstrap';
import { Form } from 'react-bootstrap';
import { CONFIG } from '../../../utils/config';
import reports from '../../../assets/Images/price-data.png'
import HistoricalSampleReports from '../../HistoricalReports/HistoricalSampleReports';
import trendanalysis from '../../../assets/Images/trend-analysis-icon.png';
import competitivebenchmarking from '../../../assets/Images/competitive-benchmarking-icon.png';
import revenuemanagement from '../../../assets/Images/revenue-management-icon.png';
import marketdemandinsights from '../../../assets/Images/marketdemand-insights-icon.png';

const ReportHome = ({ reportType, handleSearchResult, handleSearch, handleSearchBtn, setSearchInput, openSearch,
    setOpenSearch, searchSuggestion, errors, props , searchInput,setSubmitted,submitted}) => {

    const errorsHandle = (errorMessage) => {
        if(errorMessage){
            window.globalThis.REACT_SNACK_OPEN = true;
            window.globalThis.REACT_SNACK_MESSAGE = errorMessage;
            window.globalThis.REACT_SNACK_COLOR = "error";
            document.getElementById('enable-error-snack').click();
        }
       
    }
    return (
        <>

<div className={`reports-banner ${reportType === "HistoricalData" ? "historical-reports-banner" : "market-report-banner"}`}>
                <div className='background-shadow'></div>
                <div className='banner-content'>
                    <h1> {reportType === "HistoricalData" ? "Historical Daily Rates" : "Self Storage Market Reports" }</h1>
                    <h3> {reportType === "HistoricalData" ? "Download Instant Reports for Comprehensive Market Insights" : "" }</h3>
                    <Form  onSubmit={handleSearchBtn}>
                        <Autocomplete
                            disabled={props.from === "LSS" ? true : false}
                            open={openSearch}
                            onOpen={() => setOpenSearch(true)}
                            onClose={() => setOpenSearch(false)}
                            onChange={(e, value) => {
                                handleSearchResult(value)
                            }}
                            options={searchSuggestion}  // <-- Pass the search suggestions here
                            getOptionLabel={(option) => option.place_name || ''}
                            // filterOptions={(options, state) => options} 
                            filterOptions={(options, state) => {
                                if (state.inputValue?.trim() === '' && searchInput?.trim() === "") {
                                    return []; // Return empty array when input value is empty
                                }
                                return options;
                            }}
                            onInputChange={(e, value) => {
                                setSearchInput(value);
                                setSubmitted(false);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    name="address"
                                    placeholder={"Enter a full address"}
                                    onChange={handleSearch}
                                    onClick={() => setSubmitted(false)} 
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                          event.preventDefault(); 
                                        }
                                      }}
                                />
                            )}
                        />
                        <Button id="searchbtn"  className='common-search' disabled={props.from === "LSS" ? true : false}

                            onClick={handleSearchBtn}><FontAwesomeIcon icon={faSearch} /></Button>
                    </Form>
                    {submitted && Object.entries(errors).length > 0 && (
                        Object.entries(errors).map(([errorName, errorMessage]) => (
                            
                            errorsHandle(errorMessage)
                        ))
                    )}
                    <div className='highlight-lists'>
                        <Row>
                        <Col md={reportType === "HistoricalData" ? 2 : 1}></Col>
                            <Col md={2}>
                                <div className='icon-with-content'>
                                    <div className='icon_image'>
                                    <img src={reportType === "HistoricalData" ? trendanalysis : gpin } />
                                    {/* <img src={gpin} /> */}
                                    </div>
                                    <h5>{reportType === "HistoricalData" ? "Trend Analysis" :  "Existing and planned supply, Sq Ft per Capita"}</h5>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='icon-with-content'>
                                    <div className='icon_image'>
                                    <img src={reportType === "HistoricalData" ? competitivebenchmarking : currencyicon } />
                                    </div>
                                    <h5>{reportType === "HistoricalData" ? "Competitive Benchmarking" : "Current and historical rate trends"}</h5>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='icon-with-content'>
                                    <div className='icon_image'>
                                    <img src={reportType === "HistoricalData" ? revenuemanagement : populationicon } />
                                    </div>
                                    <h5>{reportType === "HistoricalData" ? "Revenue Management" : "Population, housing and income metrics"}</h5>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='icon-with-content'>
                                    <div className='icon_image'>
                                    <img src={reportType === "HistoricalData" ? marketdemandinsights : graphicon } />
                                    </div>
                                    <h5>{reportType === "HistoricalData" ? "Market Demand Insights" : "Rate volatility and inventory trends"}</h5>
                                </div>
                            </Col>
                                 {reportType !== "HistoricalData" && (
                                <Col md={2} className='last-list'>
                                    <div className='icon-with-content'>
                                    <div className='icon_image'>
                                        <img src={ownershipicon} />
                                    </div>
                                    <h5>Property profiles and ownership details</h5>
                                    </div>
                                </Col>
                                )}
                           <Col md={reportType === "HistoricalData" ? 2 : 1}></Col>
                            <Col md={12} className='banner-btn'>
                            {
                                reportType === "HistoricalData" ? (
                                  <HistoricalSampleReports reports={reports} />
                                ) : (
                                  <div>
                                    <Button
                                      className='red-btn mx-3'
                                      onClick={() => window.open(`${CONFIG.Canada_pdf}`, '_blank')}
                                    >
                                      <span>Sample Report</span>
                                      <img src={canadaicon} />
                                    </Button>
                                    <Button
                                      className='red-btn'
                                      onClick={() => window.open(`${CONFIG.Us_pdf}`, '_blank')}
                                    >
                                      <span>Sample Report</span>
                                      <img src={usicon} />
                                    </Button>
                                  </div>
                                )
                              }                              
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ReportHome;