import React, { Component } from 'react';
import './header.css';
import phone from '../../assets/Images/phone.png';
import uscountry from '../../assets/Images/LinkUSLogo.png';
import ukcountry from '../../assets/Images/uk.png';
import auscountry from '../../assets/Images/australia.png';
import nzcountry from '../../assets/Images/new-zealand.png';
import stlogo from '../../assets/Images/st-logo.png';
import {
    Collapse,
    NavbarToggler,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { CONFIG } from '../../utils/config';
import { Regions } from '../../utils/enum';
import axios from 'axios';
class Header extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.togglealert = this.togglealert.bind(this);

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onMouseEnter2 = this.onMouseEnter2.bind(this);
        this.onMouseLeave2 = this.onMouseLeave2.bind(this);
        this.onMouseEnter3 = this.onMouseEnter3.bind(this);
        this.onMouseLeave3 = this.onMouseLeave3.bind(this);
        this.state = {
            collapse: false, collapsesignin: false, collapseforgetpwd: false, collapse1: false, isOpen: false, isScrolling: false, dropdownOpen: false,
            dropdownOpen2: false, dropdownOpen3: false, modal: false, sighinerror: '', curSymb: '', regionId: '',
            isPayAsyouGo: false
        };
    }

    toggleforgetcancel() {
        this.setState(state => ({ collapseforgetpwd: !state.collapseforgetpwd }));
    }
    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }
    toggle1() {
        this.setState(state => ({ collapse1: !state.collapse1 }));
    }
    onMouseEnter() {
        this.setState({ dropdownOpen: true });
    }
    onMouseLeave() {
        this.setState({ dropdownOpen: false });
    }
    onMouseEnter2() {
        this.setState({ dropdownOpen2: true });
    }
    onMouseLeave2() {
        this.setState({ dropdownOpen2: false });
    }
    onMouseLeave3() {
        this.setState({ dropdownOpen3: false });
    }
    onMouseEnter3() {
        this.setState({ dropdownOpen3: true });
    }
    togglealert() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        
        let getpathName = window.location.pathname.split('/');
        let isPAYGvalid = [];
        for (var i = 0; i < getpathName.length; i++) {
            if (getpathName[i] === 'getonemarket') {
                isPAYGvalid.push(true);
            }
        }
        this.setState({ isPayAsyouGo: isPAYGvalid.length > 0 ? true : false })

    }
    
    render() {
        return (
            <React.Fragment>
                
                <Navbar className="header-bg">
                <Container>

                            <Col xs={12} md={6} lg={6} className='d-none d-md-block'>
                            <div>
                                
                                    <img src={phone} className="logo-img" alt="Phone" height={18} />
                                    <span className='pl-1'> Sales/Support: {CONFIG.Contact_Support}</span>
                                
                            </div>
                            </Col>
                            <Col xs={12} md={6} lg={6}>
                                <div className='header-column-flag'>
                                <ul className='nav'>
                            {CONFIG.Region_Id === Regions.US ?
                                <li ><a href={CONFIG.UK_URL} title="UK visitors click here" rel="noopener noreferrer"><img src={ukcountry}  alt="UK Country" /></a></li>
                                :
                                <li ><a href={CONFIG.US_URL} title="US visitors click here" rel="noopener noreferrer"><img src={uscountry}  alt="US Country" /></a></li>
                            }
                            <li ><a href={CONFIG.AU_URL} title="Australian visitors click here" rel="noopener noreferrer"><img src={auscountry}  alt="Aus Country" /></a></li>
                            <li ><a href={CONFIG.NZ_URL} title="NZ visitors click here" rel="noopener noreferrer"><img src={nzcountry}  alt="NZ Country" /></a></li>
                            <li className="nav-item border-right-light border-left-light">
                        <a href="https://www.linkedin.com/showcase/stortrack/" target="_blank">
                          <i className=" fab fa-linkedin-in light"></i>
                        </a>
                      </li>
                      <li className="nav-item border-right-light">
                        <a href="https://twitter.com/StorTrack" target="_blank">
                          <i className=" fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li class="nav-item border-right-light">
                        <a href="https://www.facebook.com/StorTrack/" target="_blank">
                          <i class=" fab fa-facebook-f"></i>
                        </a>
                      </li>
                           
                      
                        </ul>
                                </div>


                            </Col>
                        
        </Container>
      </Navbar>
      <Navbar light expand="md" className='newmenulayout'>
          
        <Container>
        <Row>
            <Col md={12} className='header-logo'><a href={CONFIG.STK_URL + "index.html"} > <img src={stlogo}  alt="StorTrack" className='img-fluid' /></a> <NavbarToggler onClick={this.toggle1} />   </Col>      
            </Row>

                               
                                <Collapse isOpen={this.state.collapse1} navbar className='menu-header'>
                                    <Nav  navbar>
                                        <Dropdown  nav onMouseOver={this.onMouseEnter} onMouseLeave={this.onMouseLeave} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                            <DropdownToggle nav  className='menu-bt-line' style={{cursor:"auto"}}>
                                                Solutions <i class="fas fa-chevron-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu >
                                               
                                                <div className="menu-left">
                                                    <h5>FOR OPERATORS</h5>
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "products/optimize.html"}>Optimize <i class="fa-solid fa-chevron-right"></i>
                                                        <span>Daily market intelligence platform</span>
                                                    </DropdownItem>
                                                    <DropdownItem  />
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "data-services/API.html"} >API & Custom Reports  <i class="fa-solid fa-chevron-right"></i>
                                                        <span>Custom rate data and market insights</span>
                                                    </DropdownItem>
                                                    <DropdownItem  />
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "products/stortrack-for-sitelink.html"} >StorTrack for SiteLink <i class="fa-solid fa-chevron-right"></i>
                                                        <span>Direct data into your Sitelink account</span>
                                                    </DropdownItem>
                                                </div>
                                                <div className="menu-right">
                                                    <h5>FOR INVESTORS</h5>
                                                    <DropdownItem tag={"a"} href={CONFIG.STK_URL + "products/explorer.html"} >Explorer <i class="fa-solid fa-chevron-right"></i>
                                                        <span>Market analysis tool with instant reports</span>
                                                    </DropdownItem>
                                                    <DropdownItem  />
                                                    <DropdownItem tag={"a"} href={CONFIG.Application_URL + "/market-reports"}>Instant Market Reports  <i class="fa-solid fa-chevron-right"></i>
                                                        <span>Comprehensive market insights for self-storage due diligence</span>
                                                    </DropdownItem>
                                                    <DropdownItem  />
                                                    <DropdownItem tag={"a"} href={CONFIG.Application_URL + "/historical-reports"}>Historical Rate Data <i class="fa-solid fa-chevron-right"></i>
                                                        <span>Historical rate reports for any market</span>
                                                    </DropdownItem>
                                                </div>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <NavItem dropdown  >
                                            <a href={CONFIG.STK_URL + "news"} target="_blank" className='menu-bt-line'  >Resources</a>
                                        </NavItem>
                                        <NavItem >
                                            <a href={CONFIG.STK_URL + "about.html"} className='menu-bt-line' >About</a>
                                        </NavItem>
                                        <NavItem >
                                            <a href={CONFIG.STK_URL + "contact.html"} className='menu-bt-line' >Contact</a>
                                        </NavItem>
                                    </Nav>
                                    <ul className="login-btn d-none d-sm-block">
                                        <li>
                                        <a href={CONFIG.Signin_url}  target="_blank" class="btn btn-rounded">Log in</a>
                                        </li>
                                    </ul>
                                </Collapse>
                                
                                </Container>
                            </Navbar>
                            
     
                            


            </React.Fragment>
        );
    }
}
export default Header;